import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { RoleProfileHd, RoleProfile, AddEditRoleProfile, DeleteRoleProfile } from '../components'
import { globalGetService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType } from '../../../utils'
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import { Pagination, FilterComponent, STableLoader, PageLoader, EmptyCollection } from '../../shared_elements';
import { SettingsNavWrapper } from '../../elements'
import { Table, TableBody, Paper, Grid, Button } from '@material-ui/core';
import {arrayMoveImmutable} from 'array-move';
import { roleProfilesFilters } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const RoleProfiles = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({sortKey:'', sortBy:''});
    const [deleteRoleProfile, setDeleteRoleProfile] = useState({modal:false, profile:null, item:null})
    const [addEditRoleProfile, setAddEditRoleProfile] = useState({modal:false, mode:''})
    const [roleProfilesInfo,setRoleProfilesInfo] = useState([]);
    useEffect(() => {
        getRoleProfiles(filter, 'skeletonLoader');
        trackActivity('Console Management', { page_title: 'Roles Profile', event_type: 'Page Visit', event_desc: 'Roles Profile Page Visited' })
    },[]);
    const getRoleProfiles = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/group_profile/`,{...query, all:true})
        .then(response => {
            if(checkApiStatus(response)){
                setRoleProfilesInfo(response.data.data);
                delete query.sort
                delete query.sort_by
                setFilter(query);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const onDeleteProfileRole = () => {
        let payload = {}
        if(deleteRoleProfile.item.sparta_groups.length){
            if(!deleteRoleProfile.profile){
                enqueueSnackbar('Please select the Alternate Profile', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                return
            }else{
                payload = {alternate_profile_id:deleteRoleProfile.profile.id}
            }
        }
        globalDeleteService(`console/group_profile/${deleteRoleProfile.item.id}/`,payload)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteRoleProfile({modal:false, item:null});
                getRoleProfiles(filter, 'pageLoader');
                trackActivity('Console Management', { page_title: 'Roles Profile', item_type: 'Delete', event_type:'Role(s) Profile Deleted', event_desc: 'Role(s) Profile Deleted Successfully', item_id: deleteRoleProfile.item.id})
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        })
    }
    const onSortEnd = ({oldIndex, newIndex}) => {
        if(oldIndex !== newIndex){
            let newArray = arrayMoveImmutable(roleProfilesInfo, oldIndex, newIndex)
            setRoleProfilesInfo(newArray);
            setLoading(true);
            globalPostService(`console/update_group_profile_order/`, {order_list:newArray.map(item => item.id)})
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    getRoleProfiles({}, 'pageLoader')
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false);
            })
        }
    }
    const exportRoleProfiles = () => {
        setLoading(true);
        globalExportService(`console/group_profile/`, {download:'xls'})
        .then(response => {
            setLoading(false);
            downloadFileType(response, 'Profile Type Permissions.', 'xls')
            trackActivity('Console Management', { page_title: 'Roles Profile', item_type: 'Export', event_type:'Roles Profile Exported', event_desc: 'Exported Roles Profile' })
        });
    }
    const createSortHandler = (key) => {
        let query = Object.assign({}, filter);
        query = {...query, sort:key};
        if(sort.sortKey === key){
            query = {
                ...query,
                sort_by:sort.sortBy === 'asc'?'desc':'asc'
            }
        }else{
            query = {
                ...query,
                sort_by:'asc'
            }
        }
        setSort({sortKey:key, sortBy:sort.sortBy === 'asc'?'desc':'asc'})
        getRoleProfiles(query, 'pageLoader')
    }
    return(
        <section className='roles-management' style={{margin:'24px 0 40px 276px'}}>
            <SettingsNavWrapper />
            <div>
                { skeletonLoader ? <STableLoader count={4} />:
                    <>
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={8}>
                                    <FilterComponent 
                                        filter={filter} 
                                        filterMenu={roleProfilesFilters}
                                        getResponseBack={(applyFilter) => getRoleProfiles(applyFilter, 'pageLoader')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        { checkPermission('settings', 'profile_order', 'C') ?
                                            <li className='list-inline-item'>
                                                <Button onClick={()=>setAddEditRoleProfile({modal:true, mode:'add', data:null})} color='primary' size='small' variant='contained'>Add Profile Type</Button>
                                            </li>:null
                                        }
                                        { checkPermission('settings', 'profile_order', 'EXP') ?
                                            <li className='list-inline-item'>
                                                <Button onClick={exportRoleProfiles} color='primary' size='small' variant='outlined'>Export Profile(s)</Button>
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper>
                            <Table className='mui-table-format hovered-table'>
                                <RoleProfileHd 
                                    sort={sort}
                                    createSortHandler={createSortHandler}
                                />
                                <RoleProfile 
                                    items={roleProfilesInfo}
                                    onView={(item) => setAddEditRoleProfile({modal:true,mode:'view', data:item})}
                                    onEdit={(item) => setAddEditRoleProfile({modal:true,mode:'edit', data:item})}
                                    onDelete={(item)=> setDeleteRoleProfile({modal:true, item:item})}
                                    onSortEnd={onSortEnd} 
                                    useDragHandle
                                />
                            </Table>
                            {!roleProfilesInfo.length ? <div style={{textAlign:'center'}}><EmptyCollection title="No Records found" /></div>:null}
                            
                        </Paper>
                    </>
                }   
            </div>
            { addEditRoleProfile.modal ?
                <AddEditRoleProfile 
                    addEditRoleProfile={addEditRoleProfile}
                    toggleModalFn={() => setAddEditRoleProfile({modal:false, mode:'', data:null})}
                    getResponseBack={() => getRoleProfiles(filter, 'pageLoader')}
                />
                :null
            }
            { deleteRoleProfile.modal?
                <DeleteRoleProfile 
                    deleteRoleProfile={deleteRoleProfile}
                    onDeleteProfileRole={() => onDeleteProfileRole()}
                    toggleModalFn={()=> setDeleteRoleProfile({modal:false, item:null})}
                    alterOptions={roleProfilesInfo}
                    onChangeRoleProfile={(value) => setDeleteRoleProfile({...deleteRoleProfile, profile:value})}
                />:null
            }
            { isLoading ? <PageLoader />:null}
        </section>
    )
}
export default RoleBasedAccess(RoleProfiles,['settings','profile_order','R']);