import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import AddInteriorItem from './AddInteriorItem'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import RemarksDynamicField from "../../../shared/components/RemarksDynamicField";
import { assetTypeValues, backendDateFormat } from "../../../constants";
import moment from "moment";
import { downloadFileType } from "../../../utils";
import { trackActivity } from "../../../utils/mixpanel";
const lavatoryObj = {
    name: '',
    location: null,
    part_number: '',
    serial_number: '',
    manufacturer: '',
    remarks: ''
}
const LavatoryHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const LavatoryList = ({ item, onEdit, onDelete }) => {
    return (
        <TableRow>
            <TableCell>
                <div style={{ maxWidth: '120px' }} >{item?.name || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.location?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.part_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.serial_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.manufacturer || '--'}</div>
            </TableCell>
            <TableCell>
                <RemarksDynamicField
                    item={item}
                />
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'interior', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'interior', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditLavatory = ({ params, addEditLavatory, toggleModalFn, getResponseBack ,assetInfoData}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lavatory, setLavatory] = useState(addEditLavatory.data);
    const [locationOption, setLocationOption] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        globalPostService('console/get-constants/', { constant_types: ['interior_location'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setLocationOption(response.data.data)
                }
            })
    }, [])
    const onFieldChange = (key, value) => {
        setLavatory({ ...lavatory, [key]: value })
    }
    const onAddEditLavatory = () => {
        let validationInputs = {
            name: lavatory?.name?.trim()?.length ? '' : 'Please enter Name',
            location: lavatory?.location ? '' : 'Please select Location',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (lavatory.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lavatories/${lavatory.id}/`, lavatory)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'lavatories', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited lavatories data from Edit lavatories form' })
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lavatories/`, lavatory)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'lavatories', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Added', event_desc: 'Added lavatories data from Add lavatories form' })
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditLavatory.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {lavatory?.id ? 'Edit ' : 'Add '} Lavatory
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            required
                            name='name'
                            label='Name'
                            value={lavatory?.name || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('name', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            error={error?.name}
                            helperText={error?.name || ''}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            options={locationOption}
                            getOptionLabel={option => option.label}
                            name="location"
                            value={lavatory?.location || null}
                            onChange={(e, value) => { onFieldChange('location', value); setError({ ...error, 'location': '' }) }}
                            renderInput={params => <TextField required error={error?.location} helperText={error?.location || ''} {...params} label="Location" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='part_number'
                            label='Part Number'
                            value={lavatory?.part_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='serial_number'
                            label='Serial Number'
                            value={lavatory?.serial_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={lavatory?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            id='remarks'
                            label='Remarks'
                            value={lavatory?.remarks || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditLavatory} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Lavatories = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [lavatoriesInfo, setLavatoriesInfo] = useState({ list: [], pagination: null });
    const [addEditLavatory, setAddEditLavatory] = useState({ modal: false, data: null });
    const [deleteLavatory, setDeleteLavatory] = useState({ modal: false, data: null });
    useEffect(() => {
        getLavatories({}, 'skeletonLoader');
    }, []);
    const getLavatories = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lavatories/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLavatoriesInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteLavatory = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/interior/lavatories/${deleteLavatory.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteLavatory({ modal: false, data: null })
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getLavatories({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportLavatories = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/interior/lavatories/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_lavatories ${moment().format(backendDateFormat)}`, 'xlsx')
                trackActivity('Console Management', { page_title: 'Lavatories Cabin', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Item Exported', event_desc: `Lavatories Exported Successfully`, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <div style={{ padding: '10px' }}>
            {skeletonLoader ? <STableLoader count={7} /> :
                <>
                    <AddInteriorItem
                        addPermission={checkPermission('technical_specs', 'interior', 'C')}
                        exportPermission={checkPermission('technical_specs', 'interior', 'EXP')}
                        item={lavatoriesInfo} onAddItem={() => setAddEditLavatory({ modal: true, data: lavatoryObj })}
                        isExport
                        isImport
                        onExport={() => onExportLavatories()}
                        getResponseBack={() => getLavatories({}, 'skeletonLoader')}
                        trackActivity={trackActivity('Console Management', { page_title: 'Lavatories', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Item Imported', event_desc: `Lavatories Imported Successfully`, file_extension: 'xlsx' })}
                        url={`/console/v2/aircraft/${params.aircraft_slug}/interior/lavatories/import/`}
                    />
                    <Paper style={{ maxHeight: window.innerHeight - 340 + 'px', overflow: "scroll" }}>
                        <Table className='mui-table-format' stickyHeader>
                            <LavatoryHd />
                            <TableBody>
                                {lavatoriesInfo.list.map((item, index) =>
                                    <LavatoryList
                                        key={index}
                                        item={item}
                                        onEdit={() => setAddEditLavatory({ modal: true, data: item })}
                                        onDelete={() => setDeleteLavatory({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    {lavatoriesInfo?.pagination ?
                        <Paper>
                            <Pagination
                                pagination={lavatoriesInfo.pagination}
                                onChangePage={(event, newPage) => getLavatories({ ...filter, page: newPage + 1, per_page: lavatoriesInfo.pagination.per_page }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getLavatories({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                            />
                        </Paper>
                        : null
                    }
                    <Paper>
                        {!lavatoriesInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                    </Paper>
                </>
            }
            {addEditLavatory.modal ?
                <AddEditLavatory
                    params={params}
                    assetInfoData={assetInfoData}
                    addEditLavatory={addEditLavatory}
                    toggleModalFn={() => setAddEditLavatory({ modal: false, data: null })}
                    getResponseBack={() => getLavatories({}, 'pageLoader')}
                /> : null
            }
            {deleteLavatory.modal ?
                <DeletePopUp
                    modal={deleteLavatory.modal}
                    title='Delete Lavatory'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteLavatory({ modal: false, data: null })}
                    deleteRecordFn={onDeleteLavatory}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(Lavatories);
