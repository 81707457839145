import React from "react";
import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getLocalStorageInfo } from '../../../utils';
import { checkPermission, checkForAddAsset } from '../../../console_management/utils_v2'
import config from '../../../config';
import { castleAirInstance, halcyonInstance, hanwahaInstance } from "../../../shared/components";
const AddAssetDropdown = ({onAddAsset}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const avolonInstance =  getLocalStorageInfo()?.defaultLessor?.id === 470;
    const jetStreamInstance =  getLocalStorageInfo()?.defaultLessor?.id === 442;
    const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )
    return(
        <div>
            {checkForAddAsset()  && !window.location.href.includes('archived-assets') ?
            <Button endIcon={<ArrowDropDownIcon fontSize="small" />} color='primary' size='small' variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Add Asset
            </Button> : null}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {checkPermission('technical_specs','aircraft', 'C') && !smbcInstance && !halcyonInstance && !hanwahaInstance? <MenuItem onClick={() => {handleClose();onAddAsset(1)}}>Aircraft (MSN)</MenuItem> : null}
                {checkPermission('technical_specs','engine', 'C') ? <MenuItem onClick={() => {handleClose();onAddAsset(2);}}>Engine (ESN)</MenuItem> : null}
                {checkPermission('technical_specs','apu', 'C') && !smbcInstance && !halcyonInstance && !hanwahaInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(3)}}>APU</MenuItem> : null}
                {checkPermission('technical_specs','landing_gears', 'C') && !avolonInstance && !smbcInstance && !halcyonInstance && !hanwahaInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(4)}}>Landing Gear</MenuItem> : null}
                {checkPermission('technical_specs','propeller', 'C') && !avolonInstance  && !smbcInstance && !castleAirInstance && !halcyonInstance && !hanwahaInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(5)}}>Propeller</MenuItem> : null}
                {checkPermission('technical_specs','engine', 'C') && !avolonInstance && !jetStreamInstance && !castleAirInstance && !smbcInstance  && !halcyonInstance && !hanwahaInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(6)}}>Engine Module</MenuItem> : null}
                {checkPermission('technical_specs','component', 'C') && jetStreamInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(10)}}>Component</MenuItem> : null}
                {checkPermission('technical_specs','aircraft', 'C') && !smbcInstance || checkPermission('technical_specs','engine', 'C') && !smbcInstance && !halcyonInstance && !hanwahaInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(7)}}>Import Via Transfer Key</MenuItem> : null}
            </Menu>
        </div>
    )
}
export default AddAssetDropdown;