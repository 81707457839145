import React, { useState, useEffect }  from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory, withRouter } from 'react-router';
import { Grid, Avatar, Menu, MenuItem, TextField, Tooltip, Badge, Hidden, Popper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InviteUser from './InviteUser';
import ReportAnIssue from './ReportAnIssue';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { checkApiStatus, getLocalStorageInfo } from '../utils_v2';
import { eraseGlobalCookie, getGlobalCookie } from '../../utils';
import { imgStoragePath } from '../../constants';
import { trackLogOut } from '../../utils/mixpanel';
import enableAssetListing from '../auth/apiService';
import PageLoader from './PageLoader';
import { getNotificationBarDataAc } from '../../shared/actionCreators';
import { globalGetService } from '../../globalServices';
const UserInfo = ({openInviteUser}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onLogout = () => {
        trackLogOut(getLocalStorageInfo())
        localStorage.clear();
        eraseGlobalCookie('lessorAccess')
        eraseGlobalCookie('redirectURI')
        eraseGlobalCookie('domain')
        eraseGlobalCookie('userName')
        browserHistory.push('/login');
    }

    const userDetail = getLocalStorageInfo().user;
    return(
        <>
            <span className='user-details' aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar style={{height:'30px',width:'30px',placeSelf:'center'}} alt={userDetail?.name||''} src={userDetail?.profile_pic||''} />
                <div className='flex-centered'  style={{height:'44px'}}>
                    <ul className="list-inline" style={{padding:'5px'}} >
                        <li>{ userDetail?.name ? <h5>{userDetail.name}</h5>:null}</li>
                        <li>{ userDetail?.designation ? <p>{userDetail.designation}</p>:null}</li>
                    </ul>
                    
                </div>
                <ArrowDropDownIcon style={{alignSelf:'center',marginLeft:'5px'}} fontSize='small' color='primary' />
            </span>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className='user-menu-list'
            >
                <MenuItem onClick={() => {handleClose();browserHistory.push('/user-profile')}}>Profile</MenuItem>
                <MenuItem onClick={() => {handleClose();openInviteUser()}}>Invite User</MenuItem>
                <MenuItem onClick={() => {handleClose();onLogout()}}>Logout</MenuItem>
            </Menu>
        </>
    )
}
const Header = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [reportAnIssueInfo, setReportAnIssueInfo] = useState({modal: false});
    const [inviteUserInfo, setInviteUserInfo] = useState({modal:props.location?.query?.addUser === 'true'?true:false});
    const [lessors, setLessors] = useState([]);
    useEffect(() => {
        const handleVisibilityChange = () => {
            setTimeout(() => {
            if (document.visibilityState === 'visible') {
                const lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
                const defaultLessor = getLocalStorageInfo().defaultLessor;
                if (lessorAccess?.id !== defaultLessor.id) {
                    enableAssetListing(lessorAccess.id, lessorAccess.access, getLoaderResponse,'true')
                }
            }
        }, 100);
        };
        props.dispatch(getNotificationBarDataAc())
        document.addEventListener('visibilitychange', handleVisibilityChange);
        handleVisibilityChange();
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const getLoaderResponse = (flag) => {
        setLoading(flag)
    }
    const onChangeLessor = (lessor) => {
        const lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
        enableAssetListing(lessor.id, lessorAccess.access, getLoaderResponse)
    }
    const getLessorsList = () => {
        globalGetService(`console/lessor-drop-down/`)
        .then(response => {
            if(checkApiStatus(response)){
                setLessors(response.data.data.filter(item => item.id !== getLocalStorageInfo().defaultLessor.id))
            }
        })
    }
    return(
        <>
            <header className='primary-header'>
                <Grid spacing={1} container alignItems='center'>
                    <Grid item xs={4}>
                        <a href="/" className='app-logo'>
                            <img src={imgStoragePath+"sparta_logo.png"} alt='logo' />
                        </a>
                    </Grid>
                    <Grid item xs={8}>
                        <div className='app-other-nav'>
                            <Hidden smDown={true}>
                            <div className='header-right-item short-icons'>
                                <ul className='list-inline'>
                                    <li className='list-inline-item'>
                                        <Link to='/whats-new'>
                                            <Tooltip title="What's New !" arrow><RecordVoiceOverIcon fontSize='small' color='primary' /></Tooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Tooltip title='Report An Issue' arrow><HeadsetMicIcon onClick={() => setReportAnIssueInfo({modal:true})} fontSize='small' color='primary' /></Tooltip>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Link to='/faqs'>
                                            <Tooltip title="FAQ's" arrow><ForumIcon fontSize='small' color='primary' /></Tooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Link to='/notifications'>
                                            <Tooltip title='Notifications' arrow>
                                                <Badge style={{display:'inline-block'}} badgeContent={props?.notification_count||0} color="error" max={99}>
                                                    <NotificationsActiveIcon fontSize='small' color='primary' />
                                                </Badge>
                                            </Tooltip>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            { getLocalStorageInfo()?.has_multi_lessor ?
                                <div className='console-management-modal header-right-item'>
                                    {(() => {
                                        let defaultLessor = getLocalStorageInfo()?.defaultLessor;
                                        const selectedLessor = defaultLessor?.id ? {id:defaultLessor.id, name: defaultLessor.name}:null;
                                        return(
                                            <Autocomplete
                                                disableClearable
                                                filterSelectedOptions={true}
                                                options = {lessors}
                                                getOptionLabel={option => option.name}
                                                id="lessor"
                                                value={selectedLessor}
                                                onChange={(e, value) => onChangeLessor(value)}
                                                renderInput={params => <TextField onFocus={() => getLessorsList()} {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                            />
                                        )
                                    })()}
                                </div>:null
                            }
                            </Hidden>
                            <div className='header-right-item'>
                                <UserInfo 
                                    openInviteUser={() => setInviteUserInfo({modal:true})}
                                />
                            </div>
                            
                        </div>
                    </Grid>
                </Grid>
                { inviteUserInfo?.modal ?
                    <InviteUser 
                        inviteUserInfo={inviteUserInfo}
                        toggleModalFn={() => setInviteUserInfo({modal:false})}
                    />:null
                }
                { reportAnIssueInfo?.modal ? 
                    <ReportAnIssue  
                        reportAnIssueInfo={reportAnIssueInfo}
                        toggleModalFn={() => setReportAnIssueInfo({modal:false})}
                    />:null
                }
            </header>
            <div style={{height:'45px'}}></div>
            { isLoading ? <PageLoader />:null }
        </>
    )
}
const mapStateToProps = state => {
    return state.sharedReducers.notifications;
};
export default connect(mapStateToProps)(withRouter(Header))