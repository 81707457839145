import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import AddInteriorItem from './AddInteriorItem'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { regexConstants } from "../../../constants/regEx";
import RemarksDynamicField from "../../../shared/components/RemarksDynamicField";
import { downloadFileType } from "../../../utils";
import { assetTypeValues, backendDateFormat } from "../../../constants";
import moment from "moment";
import { trackActivity } from "../../../utils/mixpanel";
const passengerCabinObj = {
    seat_type: null,
    no_of_seats: null,
    modal: '',
    part_number: '',
    convertable_or_fixed: null,
    manufacturer: '',
    remarks: ''
}
const PassengerCabinHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Seat Type</TableCell>
                <TableCell>No. of Seats</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Convertable/Fixed</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const PassengerCabinList = ({ item, onEdit, onDelete }) => {
    return (
        <TableRow>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.seat_type?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.no_of_seats || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.model || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.part_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.convertable_or_fixed?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.manufacturer || '--'}</div>
            </TableCell>
            <TableCell>
                <RemarksDynamicField
                    item={item}
                />
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'interior', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'interior', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditPassengerCabin = ({ params, addEditPassengerCabin, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [passengerCabin, setPassengerCabin] = useState(addEditPassengerCabin.data);
    const [pltConstants, setPltConstants] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        globalPostService('console/get-constants/', { constant_types: ['pax_seat_type', 'pax_seating_convertable_or_fixed'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }, [])
    const onFieldChange = (key, value) => {
        setPassengerCabin({ ...passengerCabin, [key]: value });
    }
    const onAddEditPassengerCabin = () => {
        let validationInputs = {
            seat_type: passengerCabin?.seat_type ? '' : 'Please select Seat Type',
            no_of_seats: passengerCabin?.no_of_seats ? '' : 'Please enter No. of Seats',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (passengerCabin.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/interior/passenger-seating/${passengerCabin.id}/`, passengerCabin)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'Passenger Cabin', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited Passenger Cabin data from Edit Passenger Cabin form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/interior/passenger-seating/`, passengerCabin)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'Passenger Cabin', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Added', event_desc: 'Added Passenger Cabin data from Add Passenger Cabin form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs);
        }
    }
    return (
        <Dialog
            open={addEditPassengerCabin.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {passengerCabin.id ? 'Edit ' : 'Add '} Passenger Cabin
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <Autocomplete
                            options={pltConstants.filter(optionItem => optionItem.type === 'pax_seat_type')}
                            getOptionLabel={option => option.label}
                            name="seat_type"
                            value={passengerCabin?.seat_type || null}
                            onChange={(e, value) => { onFieldChange('seat_type', value); setError({ ...error, 'seat_type': '' }) }}
                            renderInput={params => <TextField required error={error?.seat_type} helperText={error?.seat_type || ''} {...params} label="Seat Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            name='no_of_seats'
                            label='No. of Seats'
                            value={passengerCabin?.no_of_seats || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('no_of_seats', e.target.value) : e.preventDefault() }}
                            InputLabelProps={{ shrink: true }}
                            error={error?.no_of_seats}
                            helperText={error?.no_of_seats || ''}
                            onFocus={() => setError({ ...error, 'no_of_seats': '' })}
                            inputProps={{ maxLength: 3 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='model'
                            label='Model'
                            value={passengerCabin?.model || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('model', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='part_number'
                            label='Part Number'
                            value={passengerCabin?.part_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            options={pltConstants.filter(optionItem => optionItem.type === 'pax_seating_convertable_or_fixed')}
                            getOptionLabel={option => option.label}
                            name="convertable_or_fixed"
                            value={passengerCabin?.convertable_or_fixed || null}
                            onChange={(e, value) => onFieldChange('convertable_or_fixed', value)}
                            renderInput={params => <TextField {...params} label="Convertable/Fixed" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={passengerCabin?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            id='remarks'
                            label='Remarks'
                            value={passengerCabin?.remarks || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditPassengerCabin} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const PassengerCabin = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [passengerCabinsInfo, setPassengerCabinsInfo] = useState({ list: [], pagination: null });
    const [addEditPassengerCabin, setAddEditPassengerCabin] = useState({ modal: false, data: null });
    const [deletePassengerCabin, setDeletePassengerCabin] = useState({ modal: false, data: null });
    useEffect(() => {
        getPassengerCabins({}, 'skeletonLoader');
    }, []);
    const getPassengerCabins = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/interior/passenger-seating/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setPassengerCabinsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeletePassengerCabin = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/interior/passenger-seating/${deletePassengerCabin.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeletePassengerCabin({ modal: false, data: null })
                    getPassengerCabins({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportPassengerCabin = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/interior/passenger-seating/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_passanger_cabin ${moment().format(backendDateFormat)}`, 'xlsx')
                trackActivity('Console Management', { page_title: 'Passenger Cabin', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Item Exported', event_desc: `Passenger Cabin Exported`, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <div style={{ padding: '10px' }}>
            {skeletonLoader ? <STableLoader count={8} /> :
                <>
                    <AddInteriorItem
                        addPermission={checkPermission('technical_specs', 'interior', 'C')}
                        exportPermission={checkPermission('technical_specs', 'interior', 'EXP')}
                        onAddItem={() => setAddEditPassengerCabin({ modal: true, data: passengerCabinObj })}
                        item={passengerCabinsInfo}
                        isExport
                        isImport
                        onExport={() => onExportPassengerCabin()}
                        getResponseBack={() => getPassengerCabins({}, 'skeletonLoader')}
                        trackActivity={trackActivity('Console Management', { page_title: 'Passanger Cabin', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Item Imported', event_desc: `Passenger Cabin Imported`, file_extension: 'xlsx' })}
                        url={`/console/v2/aircraft/${params.aircraft_slug}/interior/passenger-seating/import/`}
                    />
                    <Paper style={{ maxHeight: window.innerHeight - 340 + 'px', overflow: "scroll" }}>
                        <Table className='mui-table-format' stickyHeader>
                            <PassengerCabinHd />
                            <TableBody>
                                {passengerCabinsInfo.list.map((item, index) =>
                                    <PassengerCabinList
                                        key={index}
                                        item={item}
                                        onEdit={() => setAddEditPassengerCabin({ modal: true, data: item })}
                                        onDelete={() => setDeletePassengerCabin({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    {passengerCabinsInfo?.pagination ?
                        <Paper>
                            <Pagination
                                pagination={passengerCabinsInfo.pagination}
                                onChangePage={(event, newPage) => getPassengerCabins({ ...filter, page: newPage + 1, per_page: passengerCabinsInfo.pagination.per_page }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getPassengerCabins({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                            />
                        </Paper>
                        : null
                    }
                    <Paper>
                        {!passengerCabinsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                    </Paper>
                </>
            }
            {addEditPassengerCabin.modal ?
                <AddEditPassengerCabin
                    params={params}
                    assetInfoData={assetInfoData}
                    addEditPassengerCabin={addEditPassengerCabin}
                    toggleModalFn={() => setAddEditPassengerCabin({ modal: false, data: null })}
                    getResponseBack={() => getPassengerCabins({}, 'pageLoader')}
                /> : null
            }
            {deletePassengerCabin.modal ?
                <DeletePopUp
                    modal={deletePassengerCabin.modal}
                    title='Delete Passenger Cabin'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeletePassengerCabin({ modal: false, data: null })}
                    deleteRecordFn={onDeletePassengerCabin}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(PassengerCabin);
