import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat, displayDateFormatShort, assetTypeValues } from '../../../constants';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import { TechSpecsNav } from '../../elements'
import { AddInteriorItem, AssetInfo } from '../components'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InfoIcon from '@material-ui/icons/Info';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType } from "../../../utils";
import { trackActivity } from "../../../utils/mixpanel";
const stcObj = {
    description: '',
    stc_number: '',
    regulatory_approval: '',
    stc_compliance_status: null,
    compliance_date: null
}
const StcModsHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>STC Number</TableCell>
                <TableCell>STC Compliance Status</TableCell>
                <TableCell>Regulatory Approval</TableCell>
                <TableCell>Compliance Date</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const StcModsList = ({ item, onEdit, onDelete }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            <TableCell>
                {item?.description && item?.description?.length > 30 ? (
                    <div style={{ maxWidth: '250px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.description.substring(0, 30)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.description || '--'}</div>
                }
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.stc_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.stc_compliance_status?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '180px' }}>{item?.regulatory_approval || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '120px' }}>{item?.compliance_date ? moment(item?.compliance_date).format(displayDateFormatShort) : '--'}</div>
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'stc_mods', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'stc_mods', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditStcMods = ({ params, addEditStcMods, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [stcMods, setStcMods] = useState(addEditStcMods.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setStcMods({ ...stcMods, [key]: value });
    }
    const onAddEditStcMods = () => {
        let validationInputs = {
            compliance_date: stcMods?.compliance_date ? moment(stcMods?.compliance_date).diff(moment().format(backendDateFormat), 'day') > 0 ? 'Cannot be Future Date' : '' : '',
            description: stcMods?.description?.trim()?.length ? '' : 'Please enter Description'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (stcMods.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/stc-mod/${stcMods.id}/`, stcMods)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'STC_Mods', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited STC_Mods data from Edit STC_Mods form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/stc-mod/`, stcMods)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'STC_Mods', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Added', event_desc: 'Added STC_Mods data from Add STC_Mods form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditStcMods.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {stcMods?.id ? 'Edit ' : 'Add '} STCs & Mods
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <TextField
                            required
                            name='description'
                            label='Description'
                            value={stcMods?.description || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            error={error?.description}
                            helperText={error?.description || ''}
                            onFocus={() => setError({ ...error, 'description': '' })}
                            inputProps={{ maxLength: 250 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='stc_number'
                            label='STC Number'
                            value={stcMods?.stc_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('stc_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='regulatory_approval'
                            label={<>Regulatory Approval <Tooltip title='EASA, FAA, Local' arrow><InfoIcon fontSize='small' style={{ verticalAlign: 'bottom', marginLeft: '3px' }} /></Tooltip></>}
                            value={stcMods?.regulatory_approval || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('regulatory_approval', e.target.value)}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            options={[{ value: 1, label: 'Complied' }, { value: 2, label: 'Not Complied' }, { value: 3, label: 'Not Applicable' }]}
                            getOptionLabel={option => option.label}
                            name="stc_compliance_status"
                            value={stcMods?.stc_compliance_status || null}
                            onChange={(e, value) => onFieldChange('stc_compliance_status', value)}
                            renderInput={params => <TextField {...params} label="STC Compliance Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="compliance_date"
                                label="Compliance Date"
                                format={fieldDateFormat}
                                fullWidth
                                maxDate={moment()}
                                error={error?.compliance_date}
                                helperText={error?.compliance_date || ''}
                                onFocus={() => setError({ ...error, 'compliance_date': '' })}
                                InputLabelProps={{ shrink: true }}
                                value={stcMods?.compliance_date || null}
                                onChange={(data, value) => { onFieldChange('compliance_date', value && moment(data).isValid() ? moment(data).format(backendDateFormat) : null); }}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditStcMods} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const StcMods = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [stcModsInfo, setStcModsInfo] = useState({ list: [], pagination: null });
    const [addEditStcMods, setAddEditStcMods] = useState({ modal: false, data: null });
    const [deleteStcMods, setDeleteStcMods] = useState({ modal: false, data: null });
    const [assetInfoData, setAssetInfoData] = useState();
    useEffect(() => {
        getStcMods({}, 'skeletonLoader');
        getAssetInfo('assetSkeletonLoader');
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getStcMods = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/stc-mod/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setStcModsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteStcMods = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/stc-mod/${deleteStcMods.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteStcMods({ modal: false, data: null })
                    getStcMods({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportStcMods = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/stc-mod/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_Stc-Mods ${moment().format(fieldDateFormat)}`, 'xlsx')
                trackActivity('Console Management', { page_title: 'STC_Mods', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Exported', event_desc: `STC_Mods Exported Successfully`, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content">
                    {skeletonLoader ? <STableLoader count={6} /> :
                        <div style={{ padding: '10px' }}>
                            <AddInteriorItem
                                item={stcModsInfo}
                                exportPermission={checkPermission('technical_specs', 'stc_mods', 'EXP')}
                                addPermission={checkPermission('technical_specs', 'stc_mods', 'C')}
                                onAddItem={() => setAddEditStcMods({ modal: true, data: stcObj })}
                                isExport
                                isImport
                                onExport={() => onExportStcMods()}
                                getResponseBack={() => getStcMods({}, 'skeletonLoader')}
                                trackActivity={trackActivity('Console Management', { page_title: 'STC_Mods', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Imported', event_desc: `STC_Mods Imported`, file_extension: 'xlsx' })}
                                url={`console/v2/aircraft/${params.aircraft_slug}/stc-mod/import/`}
                            />
                            <Paper style={{ maxHeight: window.innerHeight - 290 + 'px', overflow: "scroll" }}>
                                <Table className='mui-table-format' stickyHeader>
                                    <StcModsHd />
                                    <TableBody>
                                        {stcModsInfo.list.map((item, index) =>
                                            <StcModsList
                                                key={index}
                                                item={item}
                                                onEdit={() => setAddEditStcMods({ modal: true, data: item })}
                                                onDelete={() => setDeleteStcMods({ modal: true, data: item })}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Paper>
                                <Pagination
                                    pagination={stcModsInfo.pagination}
                                    onChangePage={(event, newPage) => getStcMods({ ...filter, page: newPage + 1, per_page: stcModsInfo.pagination.per_page }, 'pageLoader')}
                                    onChangeRowsPerPage={(event) => getStcMods({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                />
                            </Paper>
                            <Paper>
                                {!stcModsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                            </Paper>
                        </div>
                    }
                </div>
                {addEditStcMods.modal ?
                    <AddEditStcMods
                        params={params}
                        assetInfoData={assetInfoData}
                        addEditStcMods={addEditStcMods}
                        toggleModalFn={() => setAddEditStcMods({ modal: false, data: null })}
                        getResponseBack={() => getStcMods({}, 'pageLoader')}
                    /> : null
                }
                {deleteStcMods.modal ?
                    <DeletePopUp
                        modal={deleteStcMods.modal}
                        title='Delete STCs & Mods'
                        content={`Are you sure, you want to Delete?`}
                        toggleModalFn={() => setDeleteStcMods({ modal: false, data: null })}
                        deleteRecordFn={onDeleteStcMods}
                    /> : null
                }
                {isLoading ? <PageLoader /> : null}
            </Paper>
        </section>

    )
}
export default withRouter(StcMods);
