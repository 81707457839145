import React, { useState, useEffect } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Grid, Paper, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { globalDeleteService, globalExportService, globalGetService, globalPostService } from '../../../globalServices';
import { downloadFileType } from '../../../utils';
import { getPermissionKey } from '..';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { DeletePopUp, PageLoader, RevisionHistory, STableLoader, STabsLoader } from '../../shared_elements';
import { assetTypeValues, backendDateFormat, fieldDateFormat } from '../../../constants';
import { Info, SaveAlt } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AircraftTecSpecExport from './AircraftTecSpecExport';
import moment from 'moment';
import LinkedAssetInfoPopUp from './LinkedAssetInfoPopUp';
import { trackActivity } from '../../../utils/mixpanel';
import { jetStreamInstance } from '../../../shared/components';
const AssetTransfer = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [assetTransfer, setAssetTransfer] = useState({ is_contract_and_maintenance: false, is_records: false, is_technical: false })
    const onAssetTransfer = () => {
        let payload = Object.assign({}, assetTransfer);
        if (assetTransfer.is_contract_and_maintenance && assetTransfer.is_records && assetTransfer.is_technical) {
            payload = {
                ...payload,
                is_all: true
            }
        }
        setLoading(true)
        globalPostService(`console/${params?.type}/${params?.aircraft_slug}/get-data-transfer-file/?download=txt`, payload)
            .then(response => {
                downloadFileType(response.data, `${assetTypeValues[assetInfoData?.asset_type.type].label}_${assetInfoData.serial_number}_private_key.`, 'txt')
                enqueueSnackbar("Asset Transfer Key downloaded sucessfully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                trackActivity('Console Management', { page_title: 'Asset Transfer', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Transfered', event_desc: 'Asset Transferred Successfully', });
                setModal(false);
                setLoading(false)
                // browserHistory.push('/assets-listing')
            })
    }
    return (
        <>
            {checkPermission('technical_specs', 'asset', 'TFR') ?
                <Button onClick={() => { setModal(true) }} color='primary' size='small' variant='outlined'>ASSET TRANSFER</Button> : null
            }
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                    maxWidth="md"
                >
                    <DialogTitle id="scroll-dialog-title" style={{ fontSize: '22px' }}>
                        Transfer ESN {assetInfoData?.serial_number}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{ width: '500px' }}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <h4 style={{ fontSize: '16px' }}>Select Applications To Transfer</h4>
                                    <ul className='list-inline'>
                                        {getLocalStorageInfo()?.user?.permission?.contracts || getLocalStorageInfo()?.is_maintenance ?
                                            <li className='list-inline-item'>
                                                <FormControlLabel control={<Checkbox size="small" color='primary' checked={assetTransfer.is_contract_and_maintenance} onChange={(e) => setAssetTransfer({ ...assetTransfer, is_contract_and_maintenance: e.target.checked })} name="Lease & Maintenance" />} label={<p style={{ fontSize: '13px' }}>Lease & Maintenance</p>} />
                                            </li> : null
                                        }
                                        {getLocalStorageInfo()?.is_records ?
                                            <li className='list-inline-item'>
                                                <FormControlLabel control={<Checkbox size="small" color='primary' checked={assetTransfer.is_records} onChange={(e) => setAssetTransfer({ ...assetTransfer, is_records: e.target.checked })} name="Records" />} label={<p style={{ fontSize: '13px' }}>Records</p>} />
                                            </li> : null
                                        }
                                        {getLocalStorageInfo()?.is_technical ?
                                            <li className='list-inline-item'>
                                                <FormControlLabel control={<Checkbox size="small" color='primary' checked={assetTransfer.is_technical} onChange={(e) => setAssetTransfer({ ...assetTransfer, is_technical: e.target.checked })} name="Projects" />} label={<p style={{ fontSize: '13px' }}>Projects</p>} />
                                            </li> : null
                                        }
                                    </ul>
                                    <p style={{ border: '1px solid #353A4A', background: '#DFE4E9', fontSize: '12px', padding: '10px', borderRadius: '3px', marginTop: '20px' }}>You can transfer complete or partial asset data to other {jetStreamInstance?"Lessor/Holdco":"Lessor"} account on SPARTA platform. Get started by downloading transfer key.</p>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setModal(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                        <Button disabled={isLoading || (!assetTransfer?.is_contract_and_maintenance && !assetTransfer.is_records && !assetTransfer.is_technical)} onClick={onAssetTransfer} color='primary' size='small' variant='contained'>
                            {isLoading ? <CircularProgress size={24} /> : 'Download Transfer Key'}
                        </Button>
                    </DialogActions>
                </Dialog> : null
            }
        </>
    )
}
const AssetInfo = ({ params, assetInfoData, getResponseBack, skeletonLoader }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [linkedAssetInfo, setLinkedAssetInfo] = useState({});
    const [archiveModal, setArchiveModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [basicDetails, setBasicDetails] = useState(false);
    useEffect(() => {
        getBasicDetails()
    }, [])
    const onExportTechSpec = () => {
        setLoading(true);
        globalExportService(`console/${params.type}/${params.aircraft_slug}/export-tech-summary/?download=pdf`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type]?.label}-${assetInfoData?.serial_number}__Overview_Summary ${moment().format(fieldDateFormat)}`, 'pdf')
                trackActivity('Console Management', { page_title: 'Asset TechSpec', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Exported', event_desc: 'TechSpec Exported Successfully', file_extension: 'pdf' });
                setLoading(false)
            });
    }
    const onExportMiniTechSpec = () => {
        setLoading(true);
        globalExportService(`console/${params.type}/${params.aircraft_slug}/mini-tech-summary/?download=pdf`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type]?.label}-${assetInfoData?.serial_number}__mini_tech_summary ${moment().format(fieldDateFormat)}`, 'pdf')
                trackActivity('Console Management', { page_title: 'Asset Mini-TechSpec', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Exported', event_desc: 'Mini-TechSpec Exported Successfully', file_extension: 'pdf' });
                setLoading(false)
            });
    }
    const onArchiveAsset = () => {
        globalGetService(`console/archive-asset/${params.type}/${params.aircraft_slug}/true/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar('Archive Status Updated Successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    trackActivity('Console Management', { page_title: 'Asset', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Archived', event_desc: 'Asset Archived Successfully', });
                    setArchiveModal(false);
                    getResponseBack()
                }
            })
    }
    const onDeleteAsset = () => {
        globalDeleteService(`console/delete-asset/${params.type}/${params.aircraft_slug}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    trackActivity('Console Management', { page_title: 'Asset', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Deleted', event_desc: 'Asset Deleted Successfully', });
                    setDeleteModal(false);
                    browserHistory.push('/archived-assets')
                }
            })
    }

    const getAssetTimeline = () => {
        globalGetService(`console/${params.type}/${params.aircraft_slug}/asset-timeline-insights/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    // enqueueSnackbar('Archive Status Updated Successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    browserHistory.push(`/technical-specification/${params.type}/${params.aircraft_slug}/timeline${assetInfoData?.status?.value === 5 ?'?archived-assets':''}`)
                }
            })
    }
    const getBasicDetails = (loaderType) => {
        setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setBasicDetails(response.data.data);
                }
                setLoading(false);
            })
    }
    const componentStyle = {
        fontSize: '13px',
        marginLeft: '3px',
        display: 'inlineBlock'
    }
    return (
        <section>
            {skeletonLoader ? <STabsLoader /> : <>
                <Paper className='tech-specs-asset-hd'>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item md={7}>
                            <h4 style={{ marginTop: '7px' }}>
                                {assetInfoData?.asset_type?.type ?
                                    <b style={{ paddingRight: '3px' }}>{assetTypeValues[assetInfoData?.asset_type.type].label}</b> : null
                                }
                                {assetInfoData?.serial_number}
                                {assetInfoData?.engine_type?.name ?
                                    <span style={componentStyle}>({assetInfoData.engine_type.name})</span> : null
                                }
                                {assetInfoData?.aircraft_type?.name ?
                                    <span style={componentStyle}>({assetInfoData.aircraft_type.name})</span> : null
                                }
                                {assetInfoData?.apu_type?.name ?
                                    <span style={componentStyle}>({assetInfoData.apu_type.name})</span> : null
                                }
                                {
                                    basicDetails?.stand_alone == false?
                                        <Tooltip title='Click here to view Linked Asset details' arrow placement='top'>
                                            <Info style={{ fontSize: "15px", cursor: "pointer", marginLeft: '3px' }} onClick={() => setLinkedAssetInfo({ modal: true, data: assetInfoData })} />
                                        </Tooltip>
                                        : null
                                }
                            </h4>
                            <ul className='list-inline left-cta'>
                                <li className='list-inline-item'>
                                    <Button className={`status-${assetInfoData?.status?.value}`} style={{ border: 'none', margin: '7px 0' }} color='primary' variant='outlined' size='small' >
                                        {assetInfoData?.status?.label}
                                    </Button>
                                </li>
                                {
                                    checkPermission('technical_specs', getPermissionKey(params.type), 'EXP') && params.type !== 'aircraft' ?
                                        <li className='list-inline-item' >
                                            <Button onClick={onExportTechSpec} color='primary' variant='outlined' size='small' startIcon={<SaveAlt style={{ fontSize: '1.2rem' }} />} >Export</Button>
                                        </li> : null
                                }
                                {
                                    checkPermission('technical_specs', 'aircraft', 'EXP') && params.type === 'aircraft' ?
                                        <>
                                            <li className='list-inline-item'>
                                                <AircraftTecSpecExport assetInfoData={assetInfoData} />
                                            </li >
                                            <li className='list-inline-item'>
                                                <AircraftTecSpecExport assetInfoData={assetInfoData} isMarketing title={'Marketing Summary'} />
                                            </li>
                                            <li className='list-inline-item'>
                                                <Button onClick={onExportMiniTechSpec} color='primary' variant='outlined' size='small' style={{ fontSize: '12px' }} startIcon={<SaveAlt style={{ fontSize: '1.2rem' }} />} >Mini-Spec</Button>
                                            </li>
                                        </> : null
                                }
                            </ul>
                        </Grid>
                        <Grid item md={5}>
                            <ul className='list-inline right-cta flex-centered '>
                                {!['hpt', 'lpt', 'hpc', 'fan'].includes(params?.type) && checkPermission('technical_specs', 'asset_timeline', 'R') ?
                                    <li className='list-inline-item'>
                                        <Button onClick={getAssetTimeline} color='primary' size='small' variant='outlined'>Asset Timeline</Button>
                                    </li> : null
                                }
                                {assetInfoData?.status?.value !== 5 && checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                                    <li className='list-inline-item'>
                                        <Button onClick={() => setArchiveModal(true)} color='primary' size='small' variant='outlined'>ARCHIVE ASSET</Button>
                                    </li> : null
                                }
                                {params.type === 'aircraft' || params.type === 'engine' ?
                                    <li className='list-inline-item '>
                                        <AssetTransfer
                                            params={params}
                                            assetInfoData={assetInfoData} />
                                    </li> : null
                                }
                                  {/* {assetInfoData?.status?.value === 5 && checkPermission('technical_specs', 'archived_assets', 'D') ?
                                    <li className='list-inline-item'>
                                        <Button onClick={() => setDeleteModal(true)} color='secondary' size='small' variant='outlined'>
                                            <DeleteOutlineIcon color='secondary' variant='outlined' />
                                            Delete ASSET</Button>
                                    </li> : null
                                } */}
                                <li className='list-inline-item'>

                                    <RevisionHistory assetInfoData={assetInfoData} isExp={checkPermission('technical_specs', getPermissionKey(params.type), 'EXP')} params={params} buttonType={true} url={params.type === 'lg' ? `audit/console/amlandinggear/` : ['hpt', 'lpt', 'hpc', 'fan'].includes(params?.type) ? `audit/console/amengine/` : `audit/console/am${params.type}/`} queryParams={{ action: '0,1', object_id: assetInfoData === null || assetInfoData === void 0 ? void 0 : assetInfoData?.id }} />
                                </li>
                            </ul>

                        </Grid>
                    </Grid>
                    <DeletePopUp
                        modal={archiveModal}
                        title={'Archive Asset'}
                        content={`Are you sure, you want to Archive?`}
                        toggleModalFn={() => setArchiveModal(false)}
                        deleteRecordFn={onArchiveAsset}
                        confirmText="Archive"
                    />
                    <DeletePopUp
                        modal={deleteModal}
                        title={'Delete Asset'}
                        content={`Are you sure, you want to Delete this Asset?`}
                        toggleModalFn={() => setDeleteModal(false)}
                        deleteRecordFn={onDeleteAsset}
                        confirmText="Delete"
                    />
                    {isLoading ? <PageLoader /> : null}
                </Paper>
                {
                    linkedAssetInfo?.modal ?
                        <LinkedAssetInfoPopUp
                            basicDetails={basicDetails}
                            linkedAssetInfo={linkedAssetInfo}
                            toggleModalFn={() => setLinkedAssetInfo({ modal: false })}
                        />
                        : null
                }
            </>
            }
        </section>
    )
}
export default withRouter(AssetInfo);