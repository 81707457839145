import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, CircularProgress } from '@material-ui/core';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createUserObj,defaultConsoleRole } from '../';
import { regexConstants } from '../../../constants/regEx';

const AddUser = ({addUser, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(createUserObj);
    const [roles, setRoles] = useState([]);
    const [usersEmail, setUsersEmail] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        getRoles();
        getUsersEmail();
    },[]);
    const getUsersEmail = () => {
        globalGetService(`console/get-user-list/`, {filter_type: 'add_lessor'})
        .then(response => {
            if(checkApiStatus(response)){
                setUsersEmail(response.data.data)
            }
        })
    }
    const getRoles = () => {
        globalGetService(`console/roles-dropdown/`)
        .then(response => {
            if(checkApiStatus(response)){
                let roles = response.data.data;
                roles.sort((a, b) => {
                    if(a.application.name < b.application.name) { return -1; }
                    if(a.application.name > b.application.name) { return 1; }
                    return 0;
                })
                setRoles(response.data.data);
            }
        })
    }
    const onFieldChange = (keyParam, value) => {
        if(keyParam === 'security_groups' &&  value.find(item => item.application.name === 'ALL')){
            setUserInfo({...userInfo, [keyParam]:[value.find(item => item.application.name === 'ALL')]});
        }else{
            setUserInfo({...userInfo, [keyParam]:value})
        }
        
    }
    const onAddUser = () => {
        let validationInputs = {
            first_name:userInfo?.first_name ? '':'Please enter First Name',
            email:userInfo?.email ? regexConstants.email.test(userInfo.email) ? '':'Please enter valid Email':'Please enter Email',
            contact_no:userInfo?.contact_no ? regexConstants.phoneNumber.test(userInfo.contact_no) ? '':'Please enter valid Contact Number':'',
            security_groups:userInfo?.security_groups?.length ?'' :'Please select Role(s)',
            reason_for_creation:userInfo?.reason_for_creation ? '':'Please enter Reason',
        };
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let consolePermission = userInfo?.security_groups?.length && (userInfo?.security_groups?.filter(item => [1, 15].includes(item?.application?.id))?.length > 0)
            let payload = Object.assign({}, userInfo);
            let user = usersEmail.find(item => item.email === payload.email);
            let updatedRole = [... userInfo?.security_groups] || []
            if(!consolePermission){
                updatedRole.push(defaultConsoleRole)
                setUserInfo({...userInfo,security_groups :updatedRole })
            }
            payload = {
                ...payload,
                security_groups:updatedRole?.length>0 && updatedRole.map(item => item.id)
            }
            if (user) {
                payload = {
                    ...payload,
                    id: user.id,
                    slug: user.slug
                }
            }
            setLoading(true);
            globalPostService(`console/create-user/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        toggleModalFn();
                    } else {
                        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                });
        }else{
            setError(validationInputs);
        }
    }
   
    return(
        <>
            <Dialog
                open={addUser.modal}
                // onClose={toggleModalFn}
                className='console-management-modal'

            >
                <DialogTitle id="scroll-dialog-title">
                    Add User
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <TextField 
                                    required
                                    id='email'
                                    label='Email'
                                    value={userInfo?.email||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.email?true:false}
                                    helperText={error?.email||''}
                                    onChange={(e) => onFieldChange('email', e.target.value)}
                                    onFocus={() => setError({...error, 'email':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField 
                                    id='contact_no'
                                    label='Contact Number'
                                    value={userInfo?.contact_no||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.contact_no?true:false}
                                    helperText={error?.contact_no||''}
                                    onChange={(e) => onFieldChange('contact_no', e.target.value)}
                                    onFocus={() => setError({...error, 'contact_no':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField 
                                    required
                                    id='first_name'
                                    label='First Name'
                                    value={userInfo?.first_name||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.first_name?true:false}
                                    helperText={error?.first_name||''}
                                    onChange={(e) => onFieldChange('first_name', e.target.value)}
                                    onFocus={() => setError({...error, 'first_name':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField 
                                    id='last_name'
                                    label='Last Name'
                                    value={userInfo?.last_name||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('last_name', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField 
                                    required
                                    id='reason_for_creation'
                                    label='Reason For Creation'
                                    value={userInfo?.reason_for_creation||''}
                                    fullWidth
                                    margin="normal"
                                    error={error?.reason_for_creation?true:false}
                                    helperText={error?.reason_for_creation||''}
                                    onChange={(e) => onFieldChange('reason_for_creation', e.target.value)}
                                    onFocus={() => setError({...error, 'reason_for_creation':''})}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField 
                                    id='designation'
                                    label='Designation'
                                    value={userInfo?.designation||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('designation', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField 
                                    id='organization'
                                    label='Organization'
                                    value={userInfo?.organization||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('organization', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                {(() => {
                                    let roleOptions = roles;
                                    if(userInfo?.security_groups?.length){
                                        if(userInfo.security_groups.find(item => item.application.name === 'ALL')){
                                            roleOptions = [];
                                        }else{
                                            let applicationIds = userInfo?.security_groups ? userInfo?.security_groups.map(item => item.application.id):[]
                                            roleOptions = roles.filter(item => !applicationIds.includes(item.application.id))
                                        }
                                    }
                                    return(
                                        <Autocomplete
                                            multiple
                                            filterSelectedOptions={true}
                                            options = {roleOptions}
                                             getOptionLabel={option => option.name}
                                            groupBy={(option) => option.application.name}
                                            id="security_groups"
                                            value={ userInfo?.security_groups||  []}
                                            onChange={(e, value) => onFieldChange('security_groups', value)}
                                            renderInput={params => <TextField required error={error?.security_groups ? true:false} helperText={error?.security_groups||''} onFocus={() => setError({...error,'security_groups':''})} {...params} label="Select Role(s)" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    )
                                })()}
                                <p><Link style={{fontSize:'11px', textDecoration:'underline'}} to={'/settings/roles/'} target="_blank">View Role Permissions</Link></p>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{fontSize:'13px', color:'#000', fontWeight:500, marginTop:'10px'}}>
                                        User Type
                                    </FormLabel>
                                    <RadioGroup row aria-label="user_role" name="user_role" defaultValue="top">
                                        <FormControlLabel value="1" control={<Radio checked={userInfo?.user_role ===1} onChange={() => onFieldChange('user_role',1)} size='small' color="primary" />} label="Employee" />
                                        <FormControlLabel value="2" control={<Radio checked={userInfo?.user_role ===2} onChange={() => onFieldChange('user_role',2)} size='small' color="primary" />} label="Client" />
                                        <FormControlLabel value="3" control={<Radio checked={userInfo?.user_role ===3} onChange={() => onFieldChange('user_role',3)} size='small' color="primary" />} label="Contractor" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={()=>{toggleModalFn();setUserInfo()}} color='primary' variant='outlined' size='small'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onAddUser} color='primary' variant='contained' size='small'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AddUser;