import React, { useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Paper, TextField } from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { withRouter } from 'react-router';
import { checkApiStatus } from '../../utils_v2';
import { ExportMenu, PageLoader } from '../../shared_elements';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkPermission } from '../../../console_management/utils_v2';
import { jetStreamInstance } from '../../../shared/components';
import { trackActivity } from '../../../utils/mixpanel';

const UtilsInvReport = ({ownerList}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [lessor_name_id, setLessor_name_id] = useState([]);
    const [asset_type_id, setAssetTypeId] = useState([]);
    const [assetList, setAssetList] = useState([])
    const [selectAllLessor, setSelectAllLessor] = useState(false);
    const [selectAllAsset, setSelectAllAsset] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    useEffect(() => {
        getAssetList();
    },[]);

    const getAssetList = (data) => {
        if(asset_type_id.length){
            setAssetTypeId([])
        }
        let lessorStr = data ? data.map(item => (`(${item.id},${item.lessor_level})`)).toString() : ""
        globalGetService(`/console/list/?dropdown=true&lessor_name_id=${lessorStr}`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetList(response.data.data.asset);
            }
        })
    }
    const exportUtilsInvReport = (extension) => {
        let payload = {
            file_type:extension,
            start_date:start_date,
            end_date:end_date,
            asset_type_id:asset_type_id,
            lessor_name_id:lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
          }
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            start_date: start_date ? '' : 'Please enter From Date',
            end_date: end_date ? '' : 'Please enter To Date',
            asset_type_id: asset_type_id && asset_type_id.length ? '' : 'Please Select any Asset'
        }
        if(start_date == 'Invalid date'){
            validationInputs = {
                ...validationInputs,
                start_date: 'Please enter From Date'
            }
        }
        if(end_date == 'Invalid date'){
            validationInputs = {
                ...validationInputs,
                end_date: 'Please enter To Date'
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            globalPostService(`/console/export-utilisation-invoice-report/`, payload)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    trackActivity('Asset Utilisation and Invoices Report Exported', { page_title: 'Custom Report', item_type: 'Export', event_desc: 'Asset Utilisation and Invoices Report Exported Successfully', file_extension: extension })
                    setError({})
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            });
        }else{
            setError(validationInputs)
        }
    }
    const onResetErrorKey = (keyParam) => {
        setError({...error, [keyParam]:''})
    }
    return(
        <Paper square>
            <Grid container spacing={0} alignItems='center'>
                <Grid item md={12}>
                    <div className='custom-rpt-card'>
                        <div className='rpt-card-header'>
                            <Grid container spacing={1}>
                                <Grid item md={9}>
                                    <h4>Asset Utilisation and Invoices Report</h4>
                                    <p>Utilization, MR Invoice Amounts, Hours/Cycles and Rental Invoice Amount Details for Assets. Sorted by Serial Number in Individual Sheet.</p>
                                </Grid>
                                <Grid item md={3}>
                                {checkPermission('reports','custom_reports','EXP') ? 
                                    <ExportMenu 
                                        exportReportFn={(file) => exportUtilsInvReport(file.extension)}
                                        files={[{title:'EXCEL', extension:'xlsx'}, {title:'CSV', extension:'csv'}]}
                                    /> : null }
                                </Grid>
                            </Grid>
                        </div>
                        <div className='rpt-card-body'>
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="normal"
                                            id="start_date"
                                            label="From Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={start_date}
                                            error={error.start_date ? true : false}
                                            helperText={error.start_date ? error.start_date : ''}
                                            onChange={(data) => {setStartDate(('start_date', moment(data).format(backendDateFormat))); onResetErrorKey('start_date')}}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="normal"
                                            id="end_date"
                                            label="To Date"
                                            format={fieldDateFormat}
                                            disabled={!start_date ?  true : false}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment(start_date)}
                                            maxDate={moment(start_date).diff(moment(), 'month') === 0 ? moment(new Date()) :moment(start_date).add(300, 'M')}
                                            value={end_date}
                                            error={error.end_date ? true : false}
                                            helperText={error.end_date ? error.end_date : ''}
                                            onChange={(data) => {setEndDate(('end_date', moment(data).format(backendDateFormat))); onResetErrorKey('end_date')}}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={12}>
                                    <p className='lessor-select'><span onClick={() =>  {setLessor_name_id(ownerList); setSelectAllLessor(true)}}>Select All {jetStreamInstance?"Lessor/Holdco":"Lessor"}</span>{lessor_name_id.length ? <span style={{marginLeft:'8px'}} onClick={() =>  {setLessor_name_id([]); setSelectAllLessor(false)}} >Remove All {jetStreamInstance?"Lessor/Holdco":"Lessor"}</span> : null}</p>
                                    <Autocomplete
                                        options={ownerList}
                                        getOptionLabel={option => option.name}
                                        id='lessor'
                                        value={lessor_name_id}
                                        multiple
                                        onChange={(e, data) => {setLessor_name_id(data);getAssetList(data ? data : "")}}
                                        renderInput={params => <TextField  {...params} label={jetStreamInstance?"Lessor/Holdco":"Lessor"} margin="normal" placeholder={`Select ${jetStreamInstance?"Lessor/Holdco":"Lessor"}`} fullWidth InputLabelProps={{ shrink: true }} onBlur={() => setUpdateForm(false)} onFocus={() => { setUpdateForm(true); setSelectAllLessor(false)}} variant='outlined' />}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <p className='lessor-select'><span onClick={() =>  {setAssetTypeId(assetList); setSelectAllAsset(true)}}>Select All Asset</span>{asset_type_id.length ? <span style={{marginLeft:'8px'}} onClick={() => {setAssetTypeId([]); setSelectAllAsset(false)}} >Remove All Asset</span> : null}</p>
                                    <Autocomplete
                                        options={assetList}
                                        getOptionLabel={option => option.asset_name}
                                        id='asset_type_id'
                                        value={asset_type_id}
                                        multiple
                                        onChange={(e, data) => setAssetTypeId(data)}
                                        renderInput={params => <TextField required error={error.asset_type_id ? true : false} helperText={error.asset_type_id ? error.asset_type_id : ''} {...params} label="Asset" margin="normal" placeholder='Select Assets' onFocus={() => {onResetErrorKey('asset_type_id'); setUpdateForm(true); setSelectAllAsset(false)}} onBlur={() => setUpdateForm(false)} fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            { isLoading ? <PageLoader/>:null}
        </Paper>
    )
}
export default withRouter(UtilsInvReport);