import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { checkApiStatus } from '../../utils_v2';
import { ExportMenu, PageLoader } from '../../shared_elements';
import { globalGetService } from '../../../globalServices';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Paper, TextField } from "@material-ui/core";
import { jetStreamInstance } from '../../../shared/components';
import { trackActivity } from '../../../utils/mixpanel';

const PortfolioReport = ({ownerList}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lessor_name_id, setLessor_name_id] = useState([]);
    const [updateForm, setUpdateForm] = useState(false);
    const [selectAllLessor, setSelectAllLessor] = useState(false);

    const exportPortfolioReport = (extension) => {
        let lessorStr = lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
        setLoading(true);
        globalGetService(`console/export-portfolio-report/?file_type=${extension}&lessor_name_id=${lessorStr}`)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                trackActivity('Console Management', { page_title: 'Custom Report', item_type: 'Export', event_type:'Fleet Information Report Exported', event_desc: 'Fleet Information Report Exported Successfully', file_extension: extension })
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
        });
    }
    return(
        <Paper square>
            <Grid container spacing={0} alignItems='center'>
                <Grid item md={12}>
                    <div className='custom-rpt-card'>
                        <div className='rpt-card-header'>
                            <Grid container spacing={1}>
                                <Grid item md={9}>
                                        <h4 >Fleet Information Report</h4>
                                        <p>High-Level Summary of Portfolio(s); Includes Lessee and Rental Amounts. Contains No Invoicing Data.</p>
                                </Grid>
                                <Grid item md={3}>
                                    <ExportMenu 
                                        exportReportFn={(file) => exportPortfolioReport(file.extension)}
                                        files={[{title:'EXCEL', extension:'xlsx'}, {title:'CSV', extension:'csv'}]}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='rpt-card-body'>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <p className='lessor-select'><span onClick={() =>  {setLessor_name_id(ownerList); setSelectAllLessor(true)}}>Select All {jetStreamInstance?"Lessor/Holdco":"Lessor"}</span>{lessor_name_id.length ? <span style={{marginLeft:'8px'}} onClick={() =>  {setLessor_name_id([]); setSelectAllLessor(false)}} >Remove All {jetStreamInstance?"Lessor/Holdco":"Lessor"}</span> : null}</p>
                                    <Autocomplete
                                        options={ownerList}
                                        getOptionLabel={option => option.name}
                                        id='lessor'
                                        value={lessor_name_id}
                                        labelKey="name"
                                        valueKey="id"
                                        multiple
                                        onChange={(e,data) => setLessor_name_id(data)}
                                        renderInput={params => <TextField  {...params} label={jetStreamInstance?"Lessor/Holdco":"Lessor"} margin="normal" placeholder={`Select ${jetStreamInstance?"Lessor/Holdco":"Lessor"}`} fullWidth InputLabelProps={{ shrink: true }} onBlur={() => setUpdateForm(false)} onFocus={() => { setUpdateForm(true); setSelectAllLessor(false)}} variant='outlined' />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            { isLoading ? <PageLoader/>:null}
        </Paper>
    )
}
export default withRouter(PortfolioReport);