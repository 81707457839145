import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { globalGetService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType } from '../../../utils'
import { Pagination, FilterComponent, STableLoader, ExportMenu, PageLoader, DeletePopUp, EmptyCollection } from '../../shared_elements';
import { Table, TableBody, Paper, Grid, Button } from '@material-ui/core';
import { InventoryList, InventoryHd, AddEditInventory, ImportInventory, InventoryAttachments } from '../components';
import { inventoriesFilters, inventoryObj } from '..';
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from "../../../shared/components/DeploymentMessage";
const Inventories = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sortInfo, setSortInfo] = useState({sort:'', sort_by:''});
    const [filter, setFilter] = useState({});
    const [inventories, setInventories] = useState({list:[], pagination:{page:1, per_page:20, total:100}});
    const [addEditInventory, setAddEditInventory] = useState({modal:false, mode:'',data:null});
    const [inventoryAttachments, setInventoryAttachments] = useState({modal:false, data:null})
    const [deleteInventory, setDeleteInventory] = useState({modal:false, data:null});
    useEffect(() => {
        getInventories({...filter, per_page:20}, 'skeletonLoader');
        trackActivity('Console Management', { page_title: 'Inventories List', event_type: 'Page Visited', event_desc: 'Visited Inventories List' });
    },[])
    const getInventories = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true)
        globalGetService(`console/inventory/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setInventories(response.data.data);
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false)
        })
    }
    const exportInventories = (extension) => {
        setLoading(true);
        globalExportService(`console/inventory/`, {download:extension, ...filter})
        .then(response => {
            setLoading(false);
            downloadFileType(response, 'Inventories.', extension);
            trackActivity('Console Management', { page_title: 'Export Inventories', event_type: 'Item Exported', event_desc: `Exported Inventories from List`, file_extension: extension });
        });
    }
    const onDeleteInventory = () => {
        globalDeleteService(`console/inventory/${deleteInventory.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteInventory({modal:false, data:null});
                getInventories({...filter, per_page:inventories?.pagination?.per_page||20}, 'skeletonLoader');
                trackActivity('Console Management', { page_title: 'Delete Inventory', event_type: 'Item Deleted', event_desc: `Deleted Inventory from List`, delete_id: deleteInventory?.data?.id  });
            }
        })
    }
    const onAttachmentDelete = (uuid) => {
        setInventoryAttachments({...inventoryAttachments, data:{
            ...inventoryAttachments.data,
            attachments: inventoryAttachments.data.attachments.filter(item => item.uuid !== uuid)
        }});
        if(addEditInventory.modal){
            setAddEditInventory({
                ...addEditInventory,
                data:{
                    ...addEditInventory.data,
                    attachments: addEditInventory.data.attachments.filter(item => item.uuid !== uuid)
                }
            })
        }
        getInventories({...filter, per_page:inventories?.pagination?.per_page||20}, 'pageLoader');
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getInventories({...filter, ...sortQuery, page:1, per_page:inventories?.pagination?.per_page||20},'pageLoader');
    }

    return(
        <section className='fleets-management' style={{margin:'24px 0 0 80px'}}>
             <DeploymentMessage />
            <div>
                { skeletonLoader ? <STableLoader count={10} />:
                    <>
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={9}>
                                    <FilterComponent 
                                        filter={filter} 
                                        filterMenu={inventoriesFilters}
                                        getResponseBack={(applyFilter) => getInventories({...applyFilter, page:1, per_page:inventories?.pagination?.per_page||20}, 'pageLoader')}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        { checkPermission('technical_specs', 'inventory', 'C') ?
                                            <>
                                                <li className="list-inline-item">
                                                    <ImportInventory getResponseBack={() => getInventories({...filter, per_page:inventories?.pagination?.per_page||20}, 'pageLoader')} />
                                                </li>
                                                <li className='list-inline-item'>
                                                    <Button onClick={() => setAddEditInventory({modal:true, mode:'add', data:inventoryObj})} color='primary' size="small" variant="contained">Add</Button>
                                                </li>
                                            </>:null
                                        }
                                        { checkPermission('technical_specs', 'inventory', 'EXP') && inventories?.list?.length ?
                                            <li className='list-inline-item'>
                                                <ExportMenu 
                                                    exportReportFn={(file) => exportInventories(file.extension)}
                                                    files={[{title:'EXCEL', extension:'xlsx'}]}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper>
                            <div style={{height: !inventories.list.length ? 'auto':`${window.innerHeight-226}px`,overflow:'auto'}}>
                                <Table className='mui-table-format'>
                                    <InventoryHd 
                                        sortInfo={sortInfo}
                                        createSortHandler={createSortHandler}
                                    />
                                    <TableBody>
                                        {inventories.list.map((item,index) =>
                                            <InventoryList 
                                                item={item}
                                                onView={() => setAddEditInventory({modal:true, mode:'view', data:item})}
                                                onEdit={() => setAddEditInventory({modal:true, mode:'edit', data:item})}
                                                onDelete={() => setDeleteInventory({modal:true,data:item})}
                                                onOpenAttachments={() => setInventoryAttachments({modal:true, data:item})}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                                {!inventories?.list?.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>:null}
                            </div>
                            <Pagination 
                                pagination={inventories.pagination}
                                onChangePage={(event, newPage) => getInventories({...filter, page:newPage+1, per_page: inventories?.pagination?.per_page||20}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getInventories({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                            />
                        </Paper>
                    </>
                }
            </div>
            { addEditInventory.modal ?
                <AddEditInventory 
                    addEditInventory={addEditInventory}
                    toggleModalFn={() => setAddEditInventory({modal:false, mode:'', data:null})}
                    getResponseBack={() => getInventories({...filter, per_page:inventories?.pagination?.per_page||20}, 'pageLoader')}
                    onOpenAttachments={() => setInventoryAttachments({modal:true, data:addEditInventory.data})}
                />:null
            }
            { deleteInventory.modal ?
                <DeletePopUp
                    modal={deleteInventory.modal}
                    title='Delete Inventory'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteInventory({modal:false, data:null})}
                    deleteRecordFn={onDeleteInventory}
                />:null
            }
            { inventoryAttachments.modal?
                <InventoryAttachments 
                    inventoryAttachments={inventoryAttachments}
                    getResponseBack={onAttachmentDelete}
                    toggleModalFn={() => {setInventoryAttachments({modal:false, data:null});}}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default Inventories;