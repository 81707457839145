import React, { useState, useEffect } from "react";
import { Paper, Tabs, Tab } from '@material-ui/core';
import { TechSpecsNav } from '../../elements'
import { CrewSeat, Lavatories, PassengerCabin, Galley, Equipments, LOPA, AssetInfo } from '../components'
import { globalGetService } from "../../../globalServices";
import { checkApiStatus } from "../../utils_v2";
import { titanAviInstance } from "../../../shared/components";
const Interior = ({params}) => {
    const [tabIndex, setTabIndex] = useState('crew-seat');
     const [assetInfoData, setAssetInfoData] = useState();
     const [isLoading, setLoading] = useState(false);
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    useEffect(() => {
        getAssetInfo('assetSkeletonLoader');
    },[]);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader'? setAssetSkeletonLoader(true):setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetInfoData(response.data.data);
            }
            loaderType === 'setAssetSkeletonLoader'? setAssetSkeletonLoader(false):setLoading(false);
        })
    }
    return(
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{minHeight:`${window.innerHeight-162}px`}}>
                <TechSpecsNav />
                <div className="tech-specs-content">
                    <Paper style={{margin:'0 10px'}}>
                        <Tabs indicatorColor="primary" value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                            <Tab label="Crew Seat" value={'crew-seat'} />
                            <Tab label="Lavatories" value={'lavatories'} />
                            {titanAviInstance?assetInfoData?.aircraft_operation!== 2?<Tab label="Passenger Cabin" value={'passenger-cabin'}/>:null
                            :<Tab label="Passenger Cabin" value={'passenger-cabin'}/>}
                            <Tab label="Galley" value={'galley'} />
                            <Tab label="Equipments" value={'equipments'} />
                            <Tab label="LOPA" value={'lopa'} />
                        </Tabs>
                    </Paper>
                    <div>
                        {tabIndex === 'crew-seat' ? <CrewSeat  assetInfoData={assetInfoData}/>:null}
                        {tabIndex === 'lavatories' ? <Lavatories assetInfoData={assetInfoData} />:null}
                        {tabIndex === 'passenger-cabin' ? <PassengerCabin assetInfoData={assetInfoData} />:null}
                        {tabIndex === 'galley' ? <Galley assetInfoData={assetInfoData} />:null}
                        {tabIndex === 'equipments' ? <Equipments assetInfoData={assetInfoData}/>:null}
                        {tabIndex === 'lopa' ? <LOPA  assetInfoData={assetInfoData}/>:null}
                    </div>
                </div>
            </Paper>
        </section>
    )
}
export default Interior;