import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Popover, MenuItem, Paper, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from "@material-ui/core";
import { SaveAlt } from '@material-ui/icons';
import { globalExportService, globalGetService } from "../../../globalServices";
import { downloadFileType } from "../../../utils";
import { withRouter } from "react-router";
import { ArrowDropDownIcon } from '@material-ui/icons/ArrowDropDown';
import { EmptyCollection, PageLoader } from "../../shared_elements";
import { assetTypeValues, backendDateFormat, fieldDateFormat } from "../../../constants";
import moment from "moment";
import { exportTypes } from "..";
import { trackActivity } from "../../../utils/mixpanel";
import { checkApiStatus } from "../../utils_v2";

const AircraftTecSpecExport = ({ params, assetInfoData, title, isMarketing, type }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [majorAssemblyInfo, setMajorAssemblyInfo] = useState({ list: [] });
    const [anchorEl, setAnchorEl] = useState(null);
    const [modal, setModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    useEffect(() => {
        getMajorAssemblies();
    }, []);
    const getMajorAssemblies = () => {
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/major-assemblies/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setMajorAssemblyInfo(response.data.data);
                }
            })
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onExportTechSpec = (type) => {
        if (exportTypes[type]?.value === 'custom') {
            setModal(true);
            handleClose();
        }
        else {
            let url = isMarketing ? (`console/${params.type}/${params.aircraft_slug}/export-tech-marketing-summary/?download=pdf&asset_type=${exportTypes[type]?.value}`) : (`console/${params.type}/${params.aircraft_slug}/export-tech-summary/?download=pdf&asset_type=${exportTypes[type]?.value}`)
            setLoading(true);
            globalExportService(url)
                .then(response => {
                    downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type]?.label}-${assetInfoData?.serial_number}__${exportTypes[type].value}_${isMarketing ? 'marketing_summary' : 'Overview_Summary'} ${moment().format(fieldDateFormat)}`, 'pdf');
                    trackActivity('Console Management', { page_title: isMarketing ? 'Asset Marketing Summary' : 'Asset Overview Summary', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: isMarketing ? `Asset Marketing Summary Exported(${exportTypes[type]?.value})` : `Asset Tech Summary Exported(${exportTypes[type]?.value})`, event_desc: '', file_extension: 'pdf' });
                    handleClose();
                    setLoading(false);
                });
        }
    }
    const onCustomExport = (type) => {
        let assetIds = {
            'engine': selectedItems.filter(itemId => majorAssemblyInfo.list.find(item => item.id === itemId && item.major_assembly_type === 'Engine')),
            'propeller': selectedItems.filter(itemId => majorAssemblyInfo.list.find(item => item.id === itemId && item.major_assembly_type === 'Propeller')),
            'apu': selectedItems.filter(itemId => majorAssemblyInfo.list.find(item => item.id === itemId && item.major_assembly_type === 'APU')),
            'lg': selectedItems.filter(itemId => majorAssemblyInfo.list.find(item => item.id === itemId && item.major_assembly_type === 'LG'))
        };

        let urlParams = Object.entries(assetIds).map(([type, ids]) => `${type}_asset_ids=${ids}`).join('&');
        let url = isMarketing
            ? `console/${params.type}/${params.aircraft_slug}/export-tech-marketing-summary/?download=pdf&${urlParams}`
            : `console/${params.type}/${params.aircraft_slug}/export-tech-summary/?download=pdf&${urlParams}`;

        setLoading(true);
        globalExportService(url)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type]?.label}-${assetInfoData?.serial_number}__custom_${isMarketing ? 'marketing_summary' : 'Overview_Summary'} ${moment().format(fieldDateFormat)}`, 'pdf');
                trackActivity('Console Management', { page_title: isMarketing ? 'Asset Custom Marketing Summary' : 'Asset Custom Overview Summary', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: isMarketing ? `Asset Custom Marketing Summary Exported(${exportTypes[type]?.value})` : `Asset Custom Tech Summary Exported(${exportTypes[type]?.value})`, event_desc: '', file_extension: 'pdf' });
                handleClose();
                setLoading(false);
                setModal(false);
                setSelectedItems([]);
            });
    }


    const handleCheckboxChange = (assemblyId) => {
        setSelectedItems(prevSelected => {
            if (prevSelected.includes(assemblyId)) {
                return prevSelected.filter(id => id !== assemblyId);
            } else {
                return [...prevSelected, assemblyId];
            }
        });
    };
    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleClick}
                startIcon={<SaveAlt style={{ fontSize: '1.2rem' }} />}
                style={{ fontSize: '12px' }}
            >
                {title ? title : 'Export'}
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    vertical: 'top',
                }}
            >
                <Paper style={{ border: '1px solid #ccc', borderRadius: '0px', width: isMarketing ? '187px' : '' }}>
                    <div>
                        {Object.keys(exportTypes).map(typeKey => (
                            <MenuItem
                                key={typeKey}
                                style={{ borderBottom: '1px solid #ccc', fontSize: '12px' }}
                                onClick={() => onExportTechSpec(exportTypes[typeKey].value)}
                            >
                                {exportTypes[typeKey].label}
                            </MenuItem>
                        ))}
                    </div>
                </Paper>
            </Popover>
            {modal ?

                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                    maxWidth={'md'}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Custom {isMarketing ? 'Marketing Summary' : 'Overview Summary'} Export
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div className="mui-table-format" style={{ maxHeight: '400px' }}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Major Assembly Name</TableCell>
                                        <TableCell>Asset Type</TableCell>
                                        <TableCell>Position</TableCell>
                                        <TableCell>Assembly</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {majorAssemblyInfo?.list?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    id={`assembly-${index}`}
                                                    name={`assembly-${index}`}
                                                    checked={selectedItems.includes(item?.id)}
                                                    onChange={() => handleCheckboxChange(item?.id)}
                                                />
                                                {item?.major_assembly_name || '--'}
                                            </TableCell>
                                            <TableCell>{item.asset_type?.name || '--'}</TableCell>
                                            <TableCell>{item?.position?.label || '--'}</TableCell>
                                            <TableCell>
                                                    {(item?.is_assigned ? 'Assigned' : item?.is_fitted ? 'Fitted' : item.is_titled ? 'Titled' : item?.is_assigned && item?.is_fitted ? 'Assigned/Fitted' : null)}
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {!majorAssemblyInfo.list.length ? <div style={{ textAlign: 'center', border: 'dotted', color: '#d2e1e7', padding: '50px' }}><EmptyCollection title={<h4 style={{ color: '#a0bdc8' }}>No Data found</h4>} /></div> : null}
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => { setModal(false); setSelectedItems([]) }} color='primary' size='small' variant='outlined'>Cancel</Button>
                        {
                            majorAssemblyInfo.list.length ?
                                <Button disabled={isLoading||selectedItems.length===0} onClick={onCustomExport} color='primary' size='small' variant='contained'>
                                    {isLoading ? <CircularProgress size={24} /> : 'Export'}
                                </Button> : null
                        }
                    </DialogActions>
                </Dialog> : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    );
};

export default withRouter(AircraftTecSpecExport);
