import React, { Fragment, useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, TextField, Container,Tooltip} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { Link, withRouter, browserHistory } from 'react-router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SolutionLogoConst from './SolutionLogoCont';
import { regexConstants } from '../../../constants/regEx'
import { useSnackbar } from 'notistack';
import { checkApiStatus } from "../../utils_v2";
import { globalPostService } from "../../../globalServices";


const SetResetPwdForm = ({ params, getLoaderResponse, isLoading}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userInfo, setUserInfo] = useState({ password: '', confirm_password: '' });
    const [pwdVisibility, setPwdVisibility] = useState({ password: false, confirm_password: false })
    const [error, setError] = useState({});

    const onResetPassword = (e) => {
        e.preventDefault();
        let validationInputs = {
            password: userInfo?.password?.trim()?.length ? regexConstants.password.test(userInfo.password) ? '' : 'Please enter valid Password' : 'Please enter Password',
            confirm_password: userInfo?.confirm_password?.trim()?.length ? regexConstants.password.test(userInfo.confirm_password) ? '' : 'Please enter valid Password' : 'Please enter Password'
        }
        if (userInfo.password?.trim()?.length !== userInfo.confirm_password.trim()?.length) {
            if (userInfo.password !== userInfo.confirm_password) {
                validationInputs = {
                    ...validationInputs, confirm_password: 'Passwords do not match.Try Again'
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = {
                ...userInfo,
                uSlug: params.token.split('_')[0],
                token: params.token.split('_')[1]
            }
            getLoaderResponse(true)
            globalPostService('api/password/reset-confirm/', payload)
                .then((response) => {
                    getLoaderResponse(false)
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        browserHistory.push('/login');
                    } else {
                        //enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    }
                })
        } else {
            setError(validationInputs)
        }
    }
    return (
        <>
            <SolutionLogoConst />
            <form className="console-forms user-auth-form" onSubmit={onResetPassword} noValidate>
                <div>
                    <Link to="/login" classname="back-icon" style={{ color: "#3f51b5" }}><ArrowBackIcon style={{ color: "#3f51b5", verticalAlign: 'top' }} />Back To Login</Link>
                </div>
                <div>
                    <h2 className="form-title" style={{margin:'10px 0 0 0'}}>{params.type === 'reset' ? 'Reset' : 'Set'} Password </h2>
                    <p className='form-p'>Please set your new password. Ensure the new password is following our Password Policy.</p>
                </div>
                <Grid container spacing={2} style={{display:'inline-flex'}}>
                    <Grid item md={12} container>
                        <TextField
                            required
                            type={pwdVisibility.password ? 'text' : 'password'}
                            name='password'
                            label={
                                <span>
                                  Password 
                                  <Tooltip placement='right' title={
                                    <>  
                                    <ul className="list-unstyled">
                                        <li>Password Rules:</li>
                                        <li>- Password can contain a maximum of 20 characters</li>
                                        <li>- Password must contain minimum of 8 characters</li>
                                        <li>- Password must contain at least 1 number</li>
                                        <li>- Password must contain at least 1 uppercase character</li>
                                        <li>- Password must contain at least 1 lowercase character</li>
                                        <li>- Password must contain at least 1 special character</li>
                                    </ul>
                                    </>
                                  } 
                                  arrow>
                                        <InfoIcon fontSize='small' style={{verticalAlign:'bottom', marginLeft:'3px'}} />
                                  </Tooltip>
                                </span>
                              }
                            placeholder='Enter Your New Password'
                            value={userInfo?.password || ''}
                            onChange={(e) => {
                                setUserInfo({ ...userInfo, password: e.target.value });
                                setError({ ...error, password: '' });
                            }}
                            error={error?.password ? true : false}
                            helperText={error?.password || ''}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true, style:{pointerEvents:'auto'}}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton onMouseDown={(e) => setPwdVisibility({ ...pwdVisibility, password: !pwdVisibility.password })}>
                                            {pwdVisibility.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            variant='outlined'
                            inputProps={{ maxLength: 20 }}                               
                        />
                    </Grid>
                    <Grid item md={12} container>
                        <TextField
                            style={{ bottom: '12px' }}
                            type={pwdVisibility.confirm_password ? 'text' : 'password'}
                            name='confirm_password'
                            label='Confirm Password'
                            required
                            placeholder='Confirm Your New Password'
                            value={userInfo?.confirm_password || ''}
                            onChange={(e) => {
                                setUserInfo({ ...userInfo, confirm_password: e.target.value });
                                setError({ ...error, confirm_password: '' });
                            }}
                            error={error?.confirm_password ? true : false}
                            helperText={error?.confirm_password || ''}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton onMouseDown={(e) => setPwdVisibility({ ...pwdVisibility, confirm_password: !pwdVisibility.confirm_password })}>
                                            {pwdVisibility.confirm_password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ maxLength: 20 }}                               
                        />
                    </Grid>
                    <Grid item md={12} container >
                        <Button fullWidth type='submit' color='primary' size='medium' variant='contained' >{params.type === 'reset' ? 'Reset' : 'Set'}{ isLoading? "Processing..." : " Password" }</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
export default withRouter(SetResetPwdForm);