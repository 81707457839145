import React from 'react';
import { withRouter } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Info } from "@material-ui/icons";

import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { Paper, Grid, TextField, Tooltip } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
import { hanwahaInstance } from '../../../shared/components';
const MSNOnOffWing = ({ params, assets, error, onResetError, pltConstants, isEdit, onFieldChange, basicDetails,assetInfoData }) => {
    let showDetails = basicDetails?.stand_alone 
    return (
        <Paper style={{ background: "#FAF4E7", padding:"10px" , border:"1px dotted #f1e1c0", borderRadius: '2px' }}>
            <Grid container spacing={1}>
                {/* { showDetails ?
                <Grid item md={6}>
                    {(() => {
                        let options = assets?.length ? [{ id: 0, asset_name: 'Stand-alone' }, ...assets] : [];
                        return (
                            <Autocomplete
                                disabled={!isEdit}
                                options={options}
                                getOptionLabel={option => option.asset_name}
                                name="titled_aircraft"
                                value={basicDetails?.titled_aircraft ? basicDetails?.titled_aircraft : null}
                                onChange={(e, value) => { onFieldChange('titled_aircraft', value); onResetError('titled_aircraft') }}
                                renderInput={params => <TextField required error={error?.titled_aircraft} helperText={error?.titled_aircraft || ''} {...params} label="Titled MSN" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        )
                    })()}
                </Grid>
                : null} */}
                {!basicDetails?.titled_aircraft?.slug ?
                    <Grid item md={6}>
                        <TextField
                            disabled={!isEdit}
                            name='other_aircraft_msn'
                            label='Other MSN'
                            value={basicDetails?.other_aircraft_msn || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('other_aircraft_msn', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid> : null
                }
            </Grid>

            <Grid container spacing={1}>
                <Grid item md={6}>
                    {(() => {
                        let options = [{ label: 'On Wing', value: 1 }, { label: 'Off Wing', value: 2 }];
                        return (
                            <Autocomplete
                                disableClearable
                                disabled={!isEdit || !showDetails}
                                options={options}
                                getOptionLabel={option => option.label}
                                name="on_wing_status"
                                value={basicDetails?.on_wing_status ? { label: 'On Wing', value: 1 } : { label: 'Off Wing', value: 2 }}
                                onChange={(e, value) => { onFieldChange('on_wing_status', value && value.value === 1 ? true : false); onResetError('on_wing_status') }}
                                renderInput={params => <TextField required error={error?.on_wing_status} helperText={error?.on_wing_status || ''} {...params} label="Wing Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        )
                    })()}
                </Grid>
                {basicDetails?.on_wing_status === false  ?
                    <Grid item md={6}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={pltConstants.filter((optionItem) => optionItem.type === 'off_wing_status')}
                            getOptionLabel={option => option.label}
                            name="off_wing_status"
                            value={basicDetails?.off_wing_status || null}
                            onChange={(e, value) => { onFieldChange('off_wing_status', value); onResetError('off_wing_status') }}
                            renderInput={params => <TextField required error={error?.off_wing_status? true : false} helperText={error?.off_wing_status || ''} {...params} 
                            label={<span>Off Wing Condition
                                <Tooltip title={
                                    <div style={{ width: '220px' }}>
                                        Assembly can be "On Wing" if the off wing condition is "Off Wing Serviceable" only.
                                    </div>}
                                    arrow>
                                    <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                </Tooltip>
                            </span>}
                            margin="normal" fullWidth InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }} variant='outlined' />}
                        />
                    </Grid> : null
                }
            </Grid>
            {basicDetails?.on_wing_status === true && showDetails?
                <Grid container spacing={1}>
                    <Grid item md={hanwahaInstance?4:6}>
                        <TextField
                            disabled={!isEdit}
                            name='on_wing_msn'
                            label='On Wing MSN'
                            value={basicDetails?.on_wing_msn || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('on_wing_msn', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={hanwahaInstance?4:6}>
                        <TextField
                            disabled={!isEdit}
                            name='on_wing_lessor'
                            label='On Wing Owner'
                            value={basicDetails?.on_wing_lessor || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('on_wing_lessor', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    {hanwahaInstance?
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!isEdit}
                                margin="normal"
                                name="on_wing_date"
                                label={'On Wing Date'}
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetInfoData?.manufacturing_date)}
                                disableFuture
                                value={basicDetails?.on_wing_date || null}
                                onChange={(data, value) => { onFieldChange('on_wing_date', data?.format(backendDateFormat));onResetError('on_wing_date')}}
                                error={error?.on_wing_date} 
                                helperText={error?.on_wing_date || ''}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>:null}
                </Grid> : null
            }

            {basicDetails?.on_wing_status === false ?
                <Grid container spacing={1}>
                    {params.type === 'engine' &&( basicDetails?.off_wing_status?.value === 1 || basicDetails?.off_wing_status?.value === 2 || basicDetails?.off_wing_status?.value === 3 || basicDetails?.off_wing_status?.value === 4 || basicDetails?.off_wing_status?.value === 5) ?
                        <>
                            <Grid item md={4}>
                                <TextField
                                    disabled={!isEdit}
                                    name='tsn_at_removal'
                                    label='TSN of Engine Removal'
                                    value={basicDetails?.off_wing_detail?.tsn_at_removal?basicDetails?.off_wing_detail?.tsn_at_removal:''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn_at_removal', e.target.value, 'off_wing_detail') : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={!isEdit}
                                    name='csn_at_removal'
                                    label='CSN of Engine Removal'
                                    value={basicDetails?.off_wing_detail?.csn_at_removal?basicDetails?.off_wing_detail?.csn_at_removal:''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.numberNoSpace.test(e.target.value) ? onFieldChange('csn_at_removal', e.target.value, 'off_wing_detail') : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!isEdit}
                                        margin="normal"
                                        name="date_of_removal"
                                        label={`Date of Engine Removal`}
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, 'years')}
                                        value={basicDetails?.off_wing_detail?.date_of_removal || null}
                                        onChange={(data, value) => { onFieldChange('date_of_removal',moment(data).isValid()? moment(data).format(backendDateFormat):null, 'off_wing_detail'); }}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    disabled={!isEdit}
                                    name='reason_for_removal'
                                    label='Reason of Engine Removal'
                                    value={basicDetails?.off_wing_detail?.reason_for_removal || ''}
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    onChange={(e) => onFieldChange('reason_for_removal', e.target.value, 'off_wing_detail')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 250 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                {(() => {
                                    let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
                                    let optionValue = options.find(optionItem => optionItem.value === basicDetails?.off_wing_detail?.preservation_performed);
                                    return (
                                        <Autocomplete
                                            disableClearable
                                            disabled={!isEdit}
                                            options={options}
                                            getOptionLabel={option => option.label}
                                            name="preservation_performed"
                                            value={optionValue}
                                            onChange={(e, value) => onFieldChange('preservation_performed', value !== null ? value.value : null, 'off_wing_detail')}
                                            renderInput={params => <TextField {...params} label="Preservation Performed" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                        </> : null}
                    {basicDetails?.off_wing_status?.value === 2 || basicDetails?.off_wing_status?.value === 3 ?
                        <>
                            {basicDetails?.off_wing_status?.value === 2 ?
                                <>
                                    <Grid item md={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disabled={!isEdit}
                                                margin="normal"
                                                name="release_date_of_the_engine"
                                                label={`Release Date of the Assembly`}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                value={basicDetails?.off_wing_detail?.release_date_of_the_engine || null}
                                                onChange={(data, value) => { onFieldChange('release_date_of_the_engine',moment(data).isValid() ? moment(data).format(backendDateFormat):null , 'off_wing_detail'); }}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={4}>
                                        {(() => {
                                            let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
                                            let optionValue = options.find(optionItem => optionItem.value === basicDetails?.off_wing_detail?.shop_visit_report_provided);
                                            return (
                                                <Autocomplete
                                                    disableClearable
                                                    disabled={!isEdit}
                                                    options={options}
                                                    getOptionLabel={option => option.label}
                                                    name="shop_visit_report_provided"
                                                    value={optionValue}
                                                    onChange={(e, value) => onFieldChange('shop_visit_report_provided', value !== null ? value.value : null, 'off_wing_detail')}
                                                    renderInput={params => <TextField {...params} label="Shop Visit Report Provided" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                />
                                            )
                                        })()}
                                    </Grid>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            disabled={!isEdit}
                                            options={pltConstants.filter((optionItem) => optionItem.type === 'shop_visit_type')}
                                            getOptionLabel={option => option.label}
                                            name="type_of_shop_visit"
                                            value={basicDetails?.off_wing_detail?.type_of_shop_visit || null}
                                            onChange={(e, value) => onFieldChange('type_of_shop_visit', value, 'off_wing_detail')}
                                            renderInput={params => <TextField {...params} label="Type of Shop Visit" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disabled={!isEdit}
                                                margin="normal"
                                                name="expected_date_of_shipment_back_to_lessee"
                                                label={`Expected Date of Shipment Back to Lessee`}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                value={basicDetails?.off_wing_detail?.expected_date_of_shipment_back_to_lessee || null}
                                                onChange={(data, value) => { onFieldChange('expected_date_of_shipment_back_to_lessee',moment(data).isValid()?moment(data).format(backendDateFormat):null, 'off_wing_detail'); }}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </> : null
                            }
                            {basicDetails?.off_wing_status?.value === 3 ?
                                <>
                                    <Grid item md={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disabled={!isEdit}
                                                margin="normal"
                                                name="expected_release_date_of_the_engine"
                                                label={`Expected Release Date of the Assembly?`}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                value={basicDetails?.off_wing_detail?.expected_release_date_of_the_engine || null}
                                                onChange={(data, value) => { onFieldChange('expected_release_date_of_the_engine',moment(data).isValid()? moment(data).format(backendDateFormat) : null, 'off_wing_detail'); }}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disabled={!isEdit}
                                                margin="normal"
                                                name="induction_date_of_engine"
                                                label={`Induction Date of Assembly`}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                value={basicDetails?.off_wing_detail?.induction_date_of_engine || null}
                                                onChange={(data, value) => { onFieldChange('induction_date_of_engine',moment(data).isValid()? moment(data).format(backendDateFormat):null, 'off_wing_detail'); }}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            disabled={!isEdit}
                                            options={pltConstants.filter((optionItem) => optionItem.type === 'workscope_status')}
                                            getOptionLabel={option => option.label}
                                            name="work_scope_status"
                                            value={basicDetails?.off_wing_detail?.work_scope_status || null}
                                            onChange={(e, value) => onFieldChange('work_scope_status', value, 'off_wing_detail')}
                                            renderInput={params => <TextField {...params} label="Workscope Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                </> : null
                            }
                            <Grid item md={4}>
                                <TextField
                                    disabled={!isEdit}
                                    name='shop_visit_name'
                                    label='Facility Name'
                                    value={basicDetails?.off_wing_detail?.shop_visit_name || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('shop_visit_name', e.target.value, 'off_wing_detail')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={!isEdit}
                                    name='shop_visit_location'
                                    label='Facility Location'
                                    value={basicDetails?.off_wing_detail?.shop_visit_location || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('shop_visit_location', e.target.value, 'off_wing_detail')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    variant='outlined'
                                />
                            </Grid>
                        </> : null
                    }
                    {basicDetails?.off_wing_status?.value === 4 || basicDetails?.off_wing_status?.value === 5 ?
                        <>
                            {basicDetails?.off_wing_status?.value === 4 ?
                                <>
                                    <Grid item md={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disabled={!isEdit}
                                                margin="normal"
                                                name="expected_date_of_installation_on_wing"
                                                label={`Expected Date of Installation On Aircraft`}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                value={basicDetails?.off_wing_detail?.expected_date_of_installation_on_wing || null}
                                                onChange={(data, value) => { onFieldChange('expected_date_of_installation_on_wing',moment(data).isValid()?moment(data).format(backendDateFormat):null, 'off_wing_detail'); }}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={4}>
                                        {(() => {
                                            let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
                                            let optionValue = options.find(optionItem => optionItem.value === basicDetails?.off_wing_detail?.shop_visit_performed);
                                            return (
                                                <Autocomplete
                                                    disableClearable
                                                    disabled={!isEdit}
                                                    options={options}
                                                    getOptionLabel={option => option.label}
                                                    name="shop_visit_performed"
                                                    value={optionValue}
                                                    onChange={(e, value) => onFieldChange('shop_visit_performed', value !== null ? value.value : null, 'off_wing_detail')}
                                                    renderInput={params => <TextField {...params} label="Shop Visit Performed" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                />
                                            )
                                        })()}
                                    </Grid>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            disabled={!isEdit}
                                            options={pltConstants.filter((optionItem) => optionItem.type === 'sv_report_status')}
                                            getOptionLabel={option => option.label}
                                            name="shop_visit_report_status"
                                            value={basicDetails?.off_wing_detail?.shop_visit_report_status || null}
                                            onChange={(e, value) => onFieldChange('shop_visit_report_status', value, 'off_wing_detail')}
                                            renderInput={params => <TextField {...params} label="Shop Visit Report Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                </> : null
                            }
                            {basicDetails?.off_wing_status?.value === 5 ?
                                <>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            disabled={!isEdit}
                                            options={pltConstants.filter((optionItem) => optionItem.type === 'sv_requirement')}
                                            getOptionLabel={option => option.label}
                                            name="shop_visit_requirement"
                                            value={basicDetails?.off_wing_detail?.shop_visit_requirement || null}
                                            onChange={(e, value) => onFieldChange('shop_visit_requirement', value, 'off_wing_detail')}
                                            renderInput={params => <TextField {...params} label="Shop Visit Requirement" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disabled={!isEdit}
                                                margin="normal"
                                                name="expected_date_of_shipment_to_shop"
                                                label={`Expected Date of Shipment to Shop`}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                value={basicDetails?.off_wing_detail?.expected_date_of_shipment_to_shop || null}
                                                onChange={(data, value) => { onFieldChange('expected_date_of_shipment_to_shop',moment(data).isValid()? moment(data).format(backendDateFormat):null, 'off_wing_detail'); }}
                                                inputVariant='outlined'
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={4}>
                                        <TextField
                                            disabled={!isEdit}
                                            name='selected_shop'
                                            label='Which Shop has Been Selected?'
                                            value={basicDetails?.off_wing_detail?.selected_shop || ''}
                                            fullWidth
                                            margin="normal"
                                            onChange={(e) => onFieldChange('selected_shop', e.target.value, 'off_wing_detail')}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </> : null
                            }

                            <Grid item md={4}>
                                <TextField
                                    disabled={!isEdit}
                                    name='off_wing_location'
                                    label='Asset Location'
                                    value={basicDetails?.off_wing_detail?.off_wing_location || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('off_wing_location', e.target.value, 'off_wing_detail')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    variant='outlined'
                                />
                            </Grid>
                        </> : null
                    }
                </Grid> : null
            }
        </Paper>
    )
}
export default withRouter(MSNOnOffWing);