import React, { useState, useEffect } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { useSnackbar } from "notistack";
import MomentUtils from '@date-io/moment';
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { regexConstants } from "../../../constants/regEx";
import EditIcon from '@material-ui/icons/Edit';
import { checkApiStatus, checkPermission } from "../../utils_v2";
import { globalPutService } from "../../../globalServices";
import { assetTypeValues, fieldDateFormat } from "../../../constants";
import { PageLoader } from "../../shared_elements";
import { trackActivity } from "../../../utils/mixpanel";
const ShopVisitCard = ({ params, shopVistInfo, shopvisit, setShopvisit, isEdit, setEdit, onFieldChange, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, seterror] = useState({});
    const editShopVisitInfo = () => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            tslsv: shopvisit.tslsv === null ? 'Please Enter TSLSV' : shopvisit?.tslsv?.toString()?.trim()?.length ? '' : 'Please Enter TSLSV',
            cslsv: shopvisit.cslsv === null ? 'Please Enter CSLSV' : shopvisit?.cslsv.toString()?.trim()?.length ? '' : 'Please Enter CSLSV'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit_card/`, shopvisit)
                .then(response => {
                    if (checkApiStatus(response)) {
                        setEdit(false);
                        enqueueSnackbar('Shop Visit Updated Sucessfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        trackActivity('Console Management', { page_title: 'ShopVisit Info', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited ShopVisit Info data from Edit ShopVisit Info form' });
                    }
                    setLoading(false)
                })
        } else {
            seterror(validationInputs)
        }
    }

    return (
        <Paper className="console-forms-fields" style={{ padding: '20px 10px', marginBottom: '15px', position: 'relative' }}>
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            disabled={true}
                            margin="normal"
                            name="shop_vist_date"
                            label="Last Shop Visit Date"
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true }}
                            minDate={moment().subtract(25, 'years')}
                            value={shopvisit?.shop_vist_date || null}
                            inputVariant='outlined'
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4}>
                    <TextField
                        required
                        disabled={true}
                        name='tslsv'
                        label='TSLSV'
                        value={shopvisit?.tslsv === null ? '' : shopvisit?.tslsv ? (shopvisit?.tslsv)?.toFixed(2) : ''}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tslsv', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        error={error.tslsv ? true : false}
                        helperText={error.tslsv || ''}
                        onFocus={() => seterror({ ...error, 'tslsv': '' })}
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        required
                        disabled={true}
                        name='cslsv'
                        label='CSLSV'
                        value={shopvisit?.cslsv === null ? '' : shopvisit?.cslsv ? (shopvisit?.cslsv)?.toFixed(2):''}
                        fullWidth
                        inputProps={{ maxlength: 10 }}
                        margin="normal"
                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('cslsv', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        error={error.cslsv ? true : false}
                        helperText={error.cslsv || ''}
                        onFocus={() => seterror({ ...error, 'cslsv': '' })}
                    />
                </Grid>
            </Grid>
            {isLoading ? <PageLoader /> : null}
        </Paper>
    )
}
export default withRouter(ShopVisitCard);