import { getLocalStorageInfo } from "../../console_management/utils_v2"
import config from '../../config';

export const leaseStatus = {
    jacLeaseStatus: [{ label: 'Prospect', value: '11' }, { label: 'In Storage', value: '10' }, { label: 'Lease Return', value: '9' }, { label: 'MOU Signed', value: '8' }, { label: 'MOU Issued', value: '7' }, { label: 'Lease Issued', value: '6' }, { label: 'Archive', value: '5' }, { label: 'Written Off/ Sold / Part out', value: '4' },
    { label: 'Off Lease', value: '2' }, { label: 'On Lease', value: '1' },],
    defaultLeaseStatus: [{ label: 'Archive', value: '5' }, { label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },],
    airAsiaLeaseStatus: [{ label: 'Prospect', value: '11' }]
}
export const revisionItemList = [
    { content_type: 'amaogandincidents', value: 'AOG and Incidents' },
    { content_type: 'amaircraft', value: 'Aircraft' },
    { content_type: 'amengine', value: 'Engine' },
    { content_type: 'amapu', value: 'APU' },
    { content_type: 'ampropeller', value: 'Propeller' },
    { content_type: 'amlandinggear', value: 'Landing Gear' },
    { content_type: 'amavionics', value: 'Avionics' },
    { content_type: 'amcrewseating', value: 'Crew Seat' },
    { content_type: 'amlavatory', value: 'Lavatory' },
    { content_type: 'ampassengerseating', value: 'Passenger Cabin' },
    { content_type: 'amgalley', value: 'Galley' },
    { content_type: 'amcabinequipments', value: 'Equipment' },
    { content_type: 'amlopahistory', value: 'LOPA' },
    { content_type: 'amstcmods', value: 'STCs & Mods' },
    { content_type: 'amremarks', value: 'Additional Remarks' },
]
export const revisionInItem = {
     'amaogandincidents': 'AOG and Incidents' ,
     'amaircraft': 'Aircraft' ,
     'amengine': 'Engine' ,
     'amapu': 'APU' ,
     'ampropeller': 'Propeller' ,
     'amlandinggear': 'Landing Gear' ,
     'amavionics': 'Avionics' ,
     'amcrewseating': 'Crew Seat' ,
     'amlavatory': 'Lavatory' ,
     'ampassengerseating': 'Passenger Cabin' ,
     'amgalley': 'Galley' ,
     'amcabinequipments': 'Equipment' ,
     'amlopahistory': 'LOPA' ,
     'amstcmods': 'STCs & Mods' ,
     'amremarks': 'Additional Remarks',
     'amutilization' : 'Custom Utilization',
     'amcomponent' : 'Component',
     'amenginelimitedlifepart' : 'Primary LLP',
     'amenginelimitedlifepartsecondary' : 'Secondary LLP',
}
export const castleAirInstance = ['api', 'uat'].includes(config.env.key) ? [479,472].includes(getLocalStorageInfo()?.defaultLessor?.id) : getLocalStorageInfo()?.defaultLessor?.id === 537 ;
export const halcyonInstance = config.env.key === "api" ? [484].includes(getLocalStorageInfo()?.defaultLessor?.id) :  [539].includes(getLocalStorageInfo()?.defaultLessor?.id);
export const jetStreamInstance =  [442,463].includes(getLocalStorageInfo()?.defaultLessor?.id);
export const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
export const lessorCheckInstance =  [406].includes(getLocalStorageInfo()?.defaultLessor?.id) ;
export const titanAviInstance = ['api','uat'].includes(config.env.key) ? getLocalStorageInfo()?.defaultLessor?.id === 480 :  getLocalStorageInfo()?.defaultLessor?.id === 542;
export const gaTelesisInstance = config.env.key === "api" ? [498].includes(getLocalStorageInfo()?.defaultLessor?.id) :  [541].includes(getLocalStorageInfo()?.defaultLessor?.id);
export const hanwahaInstance =  ['api', 'uat'].includes(config.env.key) ?[483].includes(getLocalStorageInfo()?.defaultLessor?.id):[540].includes(getLocalStorageInfo()?.defaultLessor?.id) ;




