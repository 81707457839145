import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router'
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RecordsMovement from './RecordsMovement';
import { Checkbox, Radio, RadioGroup, FormControl, FormControlLabel, FormGroup, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus,  getLocalStorageInfo, checkPermission } from '../../utils_v2';
import ServerErrorModal from "./ServerErrorModal";
import { trackActivity } from "../../../utils/mixpanel";
import { assetTypeValues } from "../../../constants";
import { PageLoader } from "../../shared_elements";
import { Info } from "@material-ui/icons";
import { assemblyTypeObj } from "..";
import ActiveLeasePopUp from "./ActiveLeasePopUp";
const LinkAssembly = ({params, linkAssembly, assetInfoData, toggleModalFn, getResponseBack, showOffWingStatus, recordsPermission}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [assemblyLoading, setAssemblyLoading] = useState(false);
    const [assembly, setAssembly] = useState( linkAssembly?.type ==='edit' ? linkAssembly.editObj :{asset:null, position:null, is_titled:true, is_assigned:true, is_fitted:false, on_wing_status:true, records_movement:false});
    const [error, setError] = useState({});
    const [linkAssemblyList, setLinkAssemblyList] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [serverError, setErrorModal] = useState({modal:false});
    const [lgSubAssembly, setLgSubAssembly] = useState([]);
    const [assemblyRecords, setAssemblyRecords] = useState({modal:false, type:'', data:null});
    const [activeLeaseModal, setActiveLeaseModal] = useState({modal:false, data:null})

    const onFieldChange = (key, value) => {
        if(key === 'is_fitted'){
            if(value){
                setAssembly({...assembly, [key]:value, is_titled:false, is_assigned:false, on_wing_status:true});
            }else{
                setAssembly({...assembly, [key]:value});
            }
        }else if(key === 'is_titled' || key === 'is_assigned'){
            setAssembly({...assembly, [key]:value, is_fitted: value ? false:assembly.is_fitted});
        }else if(key === 'is_billable' && value === false && linkAssembly?.type ==='edit' ){
            setAssemblyLoading(true)
            globalGetService(`console/aircraft/${assetInfoData?.slug}/billable-lease/`,{asset_id:assembly?.asset?.id ,asset_type:assembly?.asset?.asset_type})
            .then(response=>{
                setAssemblyLoading(false)
                if(response.data.statusCode == '428' && getLocalStorageInfo()?.user?.permission?.contracts){
                    setActiveLeaseModal({modal:true, data:assembly, response:response.data.message})
                }
                else{
                    setAssembly({...assembly, [key]:value});
                }
            })
            // setAssembly({...assembly, [key]:value, is_fitted: value ? false:assembly.is_fitted});
        }
        else{
            setAssembly({...assembly, [key]:value});
        }
        if(linkAssembly?.data?.type === 4 && key === 'position'){
            setAssembly(prevState=>({...prevState, sub_assembly:null}))
            if(value){
                getLgSubAssembly(value.value);
            }else{
                setLgSubAssembly([]);
            }
        }
    }
    useEffect(() => {
        getAssemblyList();
        getPltConstants();
        if(linkAssembly?.type ==='edit' && linkAssembly?.data?.type === 4){
            getLgSubAssembly(assembly?.position?.value)
        }
    },[]);
    const getAssemblyList = (query) => {
        setAssemblyLoading(true);
        globalGetService(`console/list/`, {link_unlink_dropdown:true, exclude_self_asset_id:assetInfoData?.id, asset_type:linkAssembly?.data?.type, linked_aircraft_type:assetInfoData?.aircraft_type?.id||'', include_titled_standalone:true, archive_status:assetInfoData?.status?.value ==5? true: null })
        .then(response => {
            if(checkApiStatus(response)){
                setLinkAssemblyList(response.data.data?.asset || []);
            }
            setAssemblyLoading(false);
        })
    }
    const getPltConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['lg_position', 'engine_position', 'off_wing_status'] })
        .then(response => {
            if (checkApiStatus(response)) {
                setPltConstants(response.data.data)
            }
        })
    }
    const getLgSubAssembly = (position) => {
        globalGetService(`console/lg_sub_assemblies_position/`, { position: position, aircraft_type_id:assetInfoData?.aircraft_type?.id })
        .then(response => {
            if (checkApiStatus(response)) {
                setLgSubAssembly(response.data.data);
            }
        })
    }
    const onLinkAssembly = (assembly) => {
        if(linkAssembly?.type ==='edit'){
            return new Promise(
                function(resolve, reject){
                    globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/link-major-assembly/${assembly.asset?.id}/`, assembly)
                    .then(response => {
                        resolve(response)
                        if (response.data.statusCode !== 422) {
                            trackActivity('Console Management', { page_title: 'Major Assembly', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited Linked Major Assembly data from Edit Major Assembly form' })
                        }
                    })
                }
            )
        }
        else{
            return new Promise(
                function(resolve, reject){
                    globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/link-major-assembly/`, assembly)
                    .then(response => {
                        resolve(response)
                        if (response.data.statusCode !== 422) {
                            trackActivity('Console Management', { page_title: 'Major Assembly', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Linked', event_desc: 'Linked Major Assembly data from Link Major Assembly form' })
                        }
                    })
                }
            )
        }
    }
    const onValidateLinkAssembly = () => {
        let subAssemblyReqCond = lgSubAssembly?.length > 0 && assetInfoData?.aircraft_type?.name !== "ATR72-600"

        let validationInputs = {
            asset:assembly?.asset ? '':'Please select Assembly',
            position:linkAssembly?.data?.type !== 3 ? assembly?.position ? '':'Please select Position' :'',
            sub_assembly:subAssemblyReqCond? assembly?.sub_assembly ? '':'Please select Sub Assembly' :'',
            // off_wing_status: showOffWingStatus ? assembly?.off_wing_status ? '':'Please select Off Wing Condition':'', 

        }
        if(!(assembly?.is_titled || assembly?.is_assigned || assembly?.is_fitted)){
            validationInputs = {
                ...validationInputs,
                installed_type:'Please select any one option'
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = Object.assign({}, assembly)
            payload={
                ...payload,
                off_wing_status: assembly?.off_wing_status || 0
            }
            if(assembly?.records_movement &&  assemblyRecords?.data?.source?.filter(item=> item?.records_details?.is_empty_folder == false).length > 0 ){
                setLoading(true);
                onLinkAssembly({...payload, mock_api:true})
                .then(response => {
                    if(checkApiStatus(response)){
                        setAssemblyRecords({...assemblyRecords, modal:true});
                    }
                    else if(response.data.statusCode === 422) {
                        setErrorModal({modal:true, data:response})
                    }
                    else{}
                    setLoading(false);
                });
                
            }else{
                setLoading(true);
                onLinkAssembly(payload)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        getResponseBack()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                    else if(response.data.statusCode === 422) {
                        setErrorModal({modal:true, data:response})
                    }
                });
            }
        }else{
            setError(validationInputs);
        }
    }
    const getLinkedAircraft = (flag) => {
        if(flag){
            setLoading(true)
            globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/records-move-details/`, {asset:assembly.asset, status:'link'})
            .then(response => {
                setLoading(false)
                if(checkApiStatus(response)){
                    setAssemblyRecords({modal:false, data:response.data.data, type:'link'});
                    setAssembly({...assembly, records_movement:true});
                }
            })
        }else{
            setAssemblyRecords({modal:false, data:null, type:''});
            setAssembly({...assembly, records_movement:false})
        }
    }
    const onRecordsMovementWithAssembly = (data) => {
        setLoading(true);
        onLinkAssembly(assembly)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                setLoading(true);
                let payload = Object.assign({},data) 
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/records-move-data/`,payload)
                .then(response => {
                    if(checkApiStatus(response)){
                        window.location.reload()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        if (response.data.statusCode !== 422) {
                            trackActivity('Console Management', { page_title: 'Record Movement', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Added', event_desc: 'Added Record Movement data from Add Record Movement form' });
                        }
                    }
                    setLoading(false);
                })
            }
        });
    }
    const showSubAssembly = assetInfoData?.aircraft_type?.default_lg_position === false ;
    // let showConditionOnAdd = linkAssembly?.type =='edit'? false : ((assembly?.asset?.on_wing_status === false) && assembly?.on_wing_status === false  )
    return(
        <>
        {
            assemblyLoading ? <PageLoader /> : null
        }
            <Dialog
                open={linkAssembly.modal}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                     {linkAssembly.type === 'edit'? 'Edit':'Link'} {linkAssembly.data.label} Assembly
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'380px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <Autocomplete
                                    disabled={linkAssembly?.type === 'edit' ? true:false}
                                    options = {linkAssemblyList}
                                    getOptionLabel={option => option.asset_name}
                                    name="asset"
                                    value={assembly?.asset||null}
                                    onChange={(e, value) => {onFieldChange('asset',value); setError({...error,'asset':''}) }}
                                    renderInput={params => <TextField placeholder={assemblyLoading ? 'Loading...':''} required error={error?.asset} helperText={error?.asset||''} {...params} label="Assembly" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            {linkAssembly?.data?.type !== 3 ?
                                <Grid item md={12}>
                                    {(() => {
                                        let lgPositions = getLocalStorageInfo()?.defaultLessor?.id === 442 ? pltConstants.filter(optionItem => optionItem.type === 'lg_position' && (optionItem.remarks === 'default' || optionItem.remarks === '442')):pltConstants.filter(optionItem => optionItem.type === 'lg_position' && optionItem.remarks === 'default');
                                        return(
                                            <Autocomplete
                                                options = {linkAssembly?.data?.type === 4 ? lgPositions:pltConstants.filter(optionItem => optionItem.type === 'engine_position')}
                                                getOptionLabel={option => option.label}
                                                name="position"
                                                value={assembly?.position||null}
                                                onChange={(e, value) => {onFieldChange('position',value); setError({...error,'position':''})}}
                                                renderInput={params => <TextField required error={error?.position} helperText={error?.position||''} {...params} label="Position" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                            />
                                        )
                                    })()}
                                    
                                </Grid>:null
                            }
                            {linkAssembly?.data?.type === 4 && showSubAssembly ?
                                <Grid item md={12}>
                                    {(()=>{
                                        let requiredCondition = lgSubAssembly?.length > 0 && assetInfoData?.aircraft_type?.name !== "ATR72-600"
                                       return(
                                        <Autocomplete
                                            options={lgSubAssembly}
                                            getOptionLabel={option => option.name}
                                            name="sub_assembly"
                                            value={assembly?.sub_assembly ? assembly?.sub_assembly :null}
                                            onChange={(e, value) => onFieldChange('sub_assembly', value)}
                                            renderInput={params => <TextField required ={requiredCondition} onFocus={()=>setError({...error,'sub_assembly':''})} error={error?.sub_assembly} helperText={error?.sub_assembly || ''} {...params} label="Sub Assembly" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />)
                                        })()}
                                </Grid>:null
                            }{
                                linkAssembly.type === 'edit'? null:
                                <Grid item md={12}>
                                <TextField
                                id='link_reason'
                                name='link_reason'
                                label='Reason'
                                value={assembly?.link_reason || ''}
                                fullWidth
                                inputProps={{ maxLength: 250 }}
                                onChange={(e) => onFieldChange('link_reason', e.target.value)}
                                margin="normal"
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                                />
                            </Grid>
                            }
                            <Grid item md={12}>
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="is_titled"
                                                control={<Checkbox checked={assembly?.is_titled} onChange={(e) => {onFieldChange('is_titled', e.target.checked); setError({...error,'installed_type':''})}} color="primary" />}
                                                label={
                                                    <span style={{display:'flex', alignItems:'center'}}>Titled
                                                        <Tooltip title='Legal Owner' arrow>
                                                            <Info style={{fontSize:'13px', marginLeft:'3px'}} />
                                                        </Tooltip>
                                                    </span>
                                                }
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="is_assigned"
                                                control={<Checkbox checked={assembly?.is_assigned} onChange={(e) => {onFieldChange('is_assigned', e.target.checked); setError({...error,'installed_type':''});}} color="primary" />}
                                                label={
                                                    <span style={{display:'flex', alignItems:'center'}}>Assigned
                                                        <Tooltip title='Assembly listed on CTA or following lease amendments.' arrow>
                                                            <Info style={{fontSize:'13px', marginLeft:'3px'}} />
                                                        </Tooltip>
                                                    </span>
                                                }
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </li>
                                    <li className="list-inline-item">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="is_fitted"
                                                control={<Checkbox checked={assembly?.is_fitted} onChange={(e) => {onFieldChange('is_fitted', e.target.checked); setError({...error,'installed_type':''});}} color="primary" />}
                                                label={
                                                    <span style={{display:'flex', alignItems:'center'}}>Fitted
                                                        <Tooltip title={<div style={{ width:'144px'}}>
                                                                Physical location of the installed assembly.
                                                            </div>} 
                                                        arrow>
                                                            <Info style={{fontSize:'13px', marginLeft:'3px'}} />
                                                        </Tooltip>
                                                    </span>
                                                }
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </li>
                                </ul>
                                { error?.installed_type ? <p className="error-msg">{error.installed_type}</p>:null}
                            </Grid>
                            <Grid item md={12}>
                                {(() => {
                                    let options = [{ label: 'On Wing', value: true }, { label: 'Off Wing', value: false }];
                                    return (
                                        <Autocomplete
                                            disabled={assembly?.is_fitted}
                                            disableClearable
                                            options={options}
                                            getOptionLabel={option => option.label}
                                            name="on_wing_status"
                                            value={assembly?.on_wing_status ? { label: 'On Wing', value: true } : { label: 'Off Wing', value: false }}
                                            onChange={(e, value) => { onFieldChange('on_wing_status', value && value.value === true ? true : false); setError({...error,'on_wing_status':''})}}
                                            renderInput={params => <TextField required error={error?.on_wing_status} helperText={error?.on_wing_status || ''} {...params} label="Wing Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                        </Grid>
                        { linkAssembly?.type !== 'edit' && assembly?.asset && getLocalStorageInfo()?.is_records ?
                           <> <div>
                                <FormControlLabel
                                    value="is_titled"
                                    control={<Checkbox onChange={(e) =>  getLinkedAircraft(e.target.checked)} color="primary" />}
                                    label="Move with Records"
                                    checked={assembly.records_movement}
                                    labelPlacement="end"
                                />
                            </div>
                            {assembly.records_movement && assemblyRecords?.data?.source?.filter(item=> item?.records_details?.is_empty_folder == false)?.length == 0 ?
                             <span style={{color:'red', fontSize:'13px'}}> No records are found for the source MSN </span> 
                           :null} </>
                            :null
                        }
                        {
                            getLocalStorageInfo()?.user?.permission?.contracts ?
                                <FormControlLabel
                                    value="is_billable"
                                    control={<Checkbox onChange={(e) => onFieldChange('is_billable', e.target.checked)} color="primary" />}
                                    label="Is Billable asset"
                                    checked={assembly.is_billable}
                                    labelPlacement="end"
                                />
                                : null}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onValidateLinkAssembly} color='primary' size='small' variant='contained'>
                        { isLoading ? <CircularProgress size={24} />:assembly?.records_movement && assemblyRecords?.data?.source?.filter(item=> item?.records_details?.is_empty_folder == false).length > 0 ? 'Next':'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            { (assemblyRecords.modal && assemblyRecords?.data?.source?.length) ? 
                <RecordsMovement 
                recordsPermission={recordsPermission}
                    loading={isLoading}
                    assemblyRecords={assemblyRecords} 
                    onRecordsMovementWithAssembly={onRecordsMovementWithAssembly}
                    toggleModalFn={() => setAssemblyRecords({...assemblyRecords, modal:false})}
                />:null
            }
            {
                serverError?.modal ?
                <ServerErrorModal 
                serverError={serverError}
                toggleModalFn={()=>setErrorModal({modal:false})}
                />: null
            }
             {
                activeLeaseModal?.modal ?
                <ActiveLeasePopUp
                modal={activeLeaseModal.modal}
                title='Active Lease Alert'
                confirmText='Continue'
                content={activeLeaseModal?.response || "This Major assembly has an active lease."}
                toggleModalFn={() => setActiveLeaseModal({ modal: false, data: null })}
                submitFn={(reason)=>{setActiveLeaseModal({modal:false}); setAssembly({...assembly, is_billable:false, empty_utilization_reason:reason ||null}); }}
            /> : null
            }
        </>
    )
}
export default withRouter(LinkAssembly);