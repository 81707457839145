import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import AddInteriorItem from './AddInteriorItem'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import RemarksDynamicField from "../../../shared/components/RemarksDynamicField";
import { downloadFileType } from "../../../utils";
import { assetTypeValues, backendDateFormat } from "../../../constants";
import moment from "moment";
import { trackActivity } from "../../../utils/mixpanel";
const crewSeatObj = {
    type_of_seat: null,
    location: null,
    part_number: '',
    serial_number: '',
    manufacturer: '',
    remarks: ''
}
const CrewSeatHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Seat Type</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}



const CrewSeatList = ({ item, onEdit, onDelete }) => {
    return (
        <TableRow>
            <TableCell>
                <div style={{ maxWidth: '140px' }}>{item?.type_of_seat?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '140px' }}>{item?.location?.label || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '140px' }}>{item?.part_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '140px' }}>{item?.serial_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ maxWidth: '140px' }}>{item?.manufacturer || '--'}</div>
            </TableCell>
            <TableCell>
                <RemarksDynamicField
                    item={item}
                />
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'interior', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'interior', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditCrewSeat = ({ params, addEditCrewSeat, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [crewSeat, setCrewSeat] = useState(addEditCrewSeat.data);
    const [pltConstants, setPltConstants] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        globalPostService('console/get-constants/', { constant_types: ['crew_seat_type', 'interior_location'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }, [])
    const onFieldChange = (key, value) => {
        if (key === 'type_of_seat') {
            if (value && (value.value === 1 || value.value === 2)) {
                setCrewSeat({ ...crewSeat, [key]: value, location: { label: 'FWD', value: 1 } })
            } else {
                setCrewSeat({ ...crewSeat, [key]: value, location: null })
            }
        } else {
            setCrewSeat({ ...crewSeat, [key]: value })
        }

    }
    const onAddEditCrewSeat = () => {
        let validationInputs = {
            type_of_seat: crewSeat?.type_of_seat ? '' : 'Please select Type of Seat',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            if (crewSeat.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/interior/crew-seating/${crewSeat.id}/`, crewSeat)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                            trackActivity('Console Management', { page_title: 'Crew seat', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited Crew Seat data from Edit Crew Seat form' })
                        }
                        setLoading(false)
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/interior/crew-seating/`, crewSeat)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Console Management', { page_title: 'Crew seat', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Item Added', event_desc: 'Added Crew Seat data from Add Crew Seat form' })
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false)
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditCrewSeat.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {crewSeat?.id ? 'Edit ' : 'Add '} Crew Seat
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <Autocomplete
                            options={pltConstants.filter(optionItem => optionItem.type === 'crew_seat_type')}
                            getOptionLabel={option => option.label}
                            name="type_of_seat"
                            value={crewSeat?.type_of_seat || null}
                            onChange={(e, value) => { onFieldChange('type_of_seat', value); setError({ ...error, 'type_of_seat': '' }) }}
                            renderInput={params => <TextField required error={error?.type_of_seat} helperText={error?.type_of_seat || ''} {...params} label="Type of Seat" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            disabled={(crewSeat?.type_of_seat?.value === 1 || crewSeat?.type_of_seat?.value === 2)}
                            options={pltConstants.filter(optionItem => optionItem.type === 'interior_location')}
                            getOptionLabel={option => option.label}
                            name="location"
                            value={crewSeat?.location || null}
                            onChange={(e, value) => onFieldChange('location', value)}
                            renderInput={params => <TextField {...params} label="Location" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='part_number'
                            label='Part Number'
                            value={crewSeat?.part_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='serial_number'
                            label='Serial Number'
                            value={crewSeat?.serial_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={crewSeat?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            name='remarks'
                            label='Remarks'
                            value={crewSeat?.remarks || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditCrewSeat} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const CrewSeat = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [crewSeatsInfo, setCrewSeatsInfo] = useState({ list: [], pagination: null });
    const [addEditCrewSeat, setAddEditCrewSeat] = useState({ modal: false, data: null });
    const [deleteCrewSeat, setDeleteCrewSeat] = useState({ modal: false, data: null });
    useEffect(() => {
        getCrewSeats({}, 'skeletonLoader');
    }, []);
    const getCrewSeats = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/interior/crew-seating/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setCrewSeatsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteCrewSeat = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/interior/crew-seating/${deleteCrewSeat.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteCrewSeat({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getCrewSeats({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportCrewSeat = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/interior/crew-seating/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_Crew_Seat ${moment().format(backendDateFormat)}`, 'xlsx')
                trackActivity('Console Management', { page_title: 'Crew Seat', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Item Exported', event_desc: `Crew Seat Exported Successfully`, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <div style={{ padding: '10px' }}>
            {skeletonLoader ? <STableLoader count={7} /> :
                <>
                    <AddInteriorItem
                        addPermission={checkPermission('technical_specs', 'interior', 'C')}
                        exportPermission={checkPermission('technical_specs', 'interior', 'EXP')}
                        onAddItem={() => setAddEditCrewSeat({ modal: true, data: crewSeatObj })}
                        isExport
                        isImport
                        item={crewSeatsInfo}
                        onExport={() => onExportCrewSeat()}
                        getResponseBack={() => getCrewSeats({}, 'skeletonLoader')}
                        trackActivity={trackActivity('Console Management', { page_title: 'Crew Seat', asset_type: 'MSN', asset_name: `${assetInfoData?.serial_number}`, event_type: 'Item Imported', event_desc: `Cres Sear Imported Successfully`, file_extension: 'xlsx' })}
                        url={`/console/v2/aircraft/${params.aircraft_slug}/interior/crew-seating/import/`}
                    />
                    <Paper style={{ maxHeight: window.innerHeight - 340 + 'px', overflow: "scroll" }}>
                        <Table className='mui-table-format' stickyHeader>
                            <CrewSeatHd />
                            <TableBody>
                                {crewSeatsInfo.list.map((item, index) =>
                                    <CrewSeatList
                                        key={index}
                                        item={item}
                                        onEdit={() => setAddEditCrewSeat({ modal: true, data: item })}
                                        onDelete={() => setDeleteCrewSeat({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    {crewSeatsInfo?.pagination ?
                        <Paper>
                            <Pagination
                                pagination={crewSeatsInfo.pagination}
                                onChangePage={(event, newPage) => getCrewSeats({ ...filter, page: newPage + 1, per_page: crewSeatsInfo.pagination.per_page }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getCrewSeats({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                            />
                        </Paper>
                        : null
                    }
                    <Paper>
                        {!crewSeatsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                    </Paper>
                </>
            }
            {addEditCrewSeat.modal ?
                <AddEditCrewSeat
                    params={params}
                    assetInfoData={assetInfoData}
                    addEditCrewSeat={addEditCrewSeat}
                    toggleModalFn={() => setAddEditCrewSeat({ modal: false, data: null })}
                    getResponseBack={() => getCrewSeats({}, 'pageLoader')}
                /> : null
            }
            {deleteCrewSeat.modal ?
                <DeletePopUp
                    modal={deleteCrewSeat.modal}
                    title='Delete Crew Seat'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteCrewSeat({ modal: false, data: null })}
                    deleteRecordFn={onDeleteCrewSeat}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(CrewSeat);
